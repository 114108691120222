import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "@/components/ui/tabs.tsx";
import { NfeInvoiceBasic } from "@/pages/invoices/product/components/invoice-basic-info.tsx";
import { NfeIssuer } from "@/pages/invoices/product/components/invoice-issuer.tsx";
import { NfeRecipient } from "@/pages/invoices/product/components/invoice-recipient.tsx";
import { NfeItems } from "@/pages/invoices/product/components/invoice-products.tsx";
import { NfeReview } from "@/pages/invoices/product/components/invoice-review.tsx";
import {
  PageContainer,
  PageContent,
  PageDescription,
  PageHeader,
  PageTitle,
} from "@/components/layout/page.tsx";

export function CreateProductInvoice() {
  return (
    <PageContainer>
      <PageHeader>
        <PageTitle>Emitir Nota Fiscal de Produto</PageTitle>
        <PageDescription>
          Preencha os dados para emitir uma nota fiscal de produto.
        </PageDescription>
      </PageHeader>
      <PageContent>
        <Tabs defaultValue="basic">
          <TabsList className="flex">
            <TabsTrigger value="basic">Informações Básicas</TabsTrigger>
            <TabsTrigger value="issuer">Emitente</TabsTrigger>
            <TabsTrigger value="recipient">Destinatário</TabsTrigger>
            <TabsTrigger value="items">Produtos</TabsTrigger>
            <TabsTrigger value="transport" disabled={true}>
              Transporte
            </TabsTrigger>
            <TabsTrigger value="charge" disabled={true}>
              Cobrança
            </TabsTrigger>
            <TabsTrigger value="additional" disabled={true}>
              Dados Adicionais
            </TabsTrigger>
            <TabsTrigger value="validation">
              Validação e Transmissão
            </TabsTrigger>
          </TabsList>

          <TabsContent value="basic">
            <NfeInvoiceBasic />
          </TabsContent>
          <TabsContent value="issuer">
            <NfeIssuer />
          </TabsContent>
          <TabsContent value="recipient">
            <NfeRecipient />
          </TabsContent>
          <TabsContent value="items">
            <NfeItems />
          </TabsContent>
          <TabsContent value="validation">
            <NfeReview />
          </TabsContent>
        </Tabs>
      </PageContent>
    </PageContainer>
  );
}
