import {
  type ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "@/components/ui/chart";
import { Area, AreaChart, CartesianGrid, XAxis } from "recharts";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "@/components/ui/card.tsx";
import { useQuery } from "@tanstack/react-query";
import { Skeleton } from "@/components/ui/skeleton.tsx";

type Stats = {
  month: number;
  received: number;
  sent: number;
  total: number;
  success: number;
  cancelled: number;
};

const months = [
  "Janeiro",
  "Fevereiro",
  "Março",
  "Abril",
  "Maio",
  "Junho",
  "Julho",
  "Agosto",
  "Setembro",
  "Outubro",
  "Novembro",
  "Dezembro",
];

const chartConfig = {
  received: {
    label: "Notas Recebidas",
    color: "hsl(var(--chart-1))",
  },
  sent: {
    label: "Notas Emitidas",
    color: "hsl(var(--chart-2))",
  },
} satisfies ChartConfig;

function getMergedStats(product: Stats[], service: Stats[]) {
  const mergedStats = [];

  for (let i = 1; i <= 12; i++) {
    const productStat = product.find((stat) => stat.month === i);
    const serviceStat = service.find((stat) => stat.month === i);

    mergedStats.push({
      received: (productStat?.received ?? 0) + (serviceStat?.received ?? 0),
      sent: (productStat?.sent ?? 0) + (serviceStat?.sent ?? 0),
      month: months[i - 1],
    });
  }

  return mergedStats;
}

export function DashboardCharts() {
  const { data: stats, isLoading } = useQuery<{
    product: Stats[];
    service: Stats[];
  }>({
    queryKey: ["stats"],
  });

  if (isLoading || !stats) {
    return <Skeleton className="w-full h-40" />;
  }

  const mergedStats = getMergedStats(stats.product, stats.service);

  return (
    <Card className="flex flex-1 flex-col overflow-hidden">
      <CardHeader>
        <CardTitle>Info Gráfico de Notas</CardTitle>
        <p className="text-sm text-muted-foreground">
          Mostrando o total de notas emitidas e recebidas no ano atual.
        </p>
      </CardHeader>
      <CardContent className="flex-1 min-h-0">
        <ChartContainer config={chartConfig} className="size-full">
          <AreaChart
            accessibilityLayer={true}
            layout="horizontal"
            data={mergedStats}
            margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
          >
            <CartesianGrid vertical={false} />
            <XAxis
              dataKey="month"
              tickLine={false}
              axisLine={false}
              tickMargin={8}
              tickFormatter={(value) => value.slice(0, 3)}
            />
            <ChartTooltip
              cursor={true}
              content={<ChartTooltipContent indicator="dot" />}
            />
            <Area
              dataKey="sent"
              type="bump"
              fill="var(--color-sent)"
              fillOpacity={0.4}
              stroke="var(--color-sent)"
            />
            <Area
              dataKey="received"
              type="bump"
              fill="var(--color-received)"
              fillOpacity={0.4}
              stroke="var(--color-received)"
            />
          </AreaChart>
        </ChartContainer>
      </CardContent>
    </Card>
  );
}
