import { X } from "lucide-react";

export function ErrorBoundaryException({ error }: { error: any }) {
  return (
    <div className={"p-2"}>
      <p className={"font-semibold flex items-center gap-x-2"}>
        <X className={"text-destructive"} />
        Ocorreu um erro ao renderizar este componente!
      </p>
      <div className={"flex flex-col"}>
        <p className={"text-sm font-medium text-muted-foreground"}>
          Detalhes do erro:
        </p>
        <span className={"text-sm text-muted-foreground"}>{error.message}</span>
        {!import.meta.env.PROD &&
          error.stack?.split("\n").map((line: any, index: number) => (
            <span key={index} className={"text-sm text-muted-foreground"}>
              {line}
            </span>
          ))}
      </div>
    </div>
  );
}
