import { useQuery } from "@tanstack/react-query";
import { DataTable, useGenericTable } from "@/components/table/data-table.tsx";
import { DataTablePagination } from "@/components/table/data-table-pagination.tsx";
import { vendorsTableColumns } from "@/pages/vendors/components/table/vendors-table-columns.tsx";
import { Package } from "@phosphor-icons/react";
import {
  CardButton,
  CardButtonContent,
  CardButtonDescription,
  CardButtonIcon,
} from "@/components/card-button.tsx";
import {
  PageContainer,
  PageContent,
  PageDescription,
  PageHeader,
  PageTitle,
} from "@/components/layout/page.tsx";

export function ViewVendors() {
  const { data } = useQuery<{
    vendors: Array<{
      id: string;
      name: string;
      document: string;
    }>;
  }>({
    queryKey: ["/vendors"],
    refetchInterval: 60000,
  });

  const table = useGenericTable({
    columns: vendorsTableColumns,
    data: data?.vendors || null,
  });

  return (
    <PageContainer>
      <PageHeader>
        <PageTitle>Fornecedores</PageTitle>
        <PageDescription>
          Gerencie todos os fornecedores que estão cadastrados no sistema.
        </PageDescription>
      </PageHeader>
      <PageContent>
        <CardButton className="w-fit" navigateTo="/vendors/new">
          <CardButtonContent>
            <CardButtonIcon>
              <Package size={20} weight="fill" />
            </CardButtonIcon>
            <CardButtonDescription>Novo fornecedor</CardButtonDescription>
          </CardButtonContent>
        </CardButton>
        <div className="space-y-4 mt-4">
          <DataTable table={table} />
          <DataTablePagination table={table} />
        </div>
      </PageContent>
    </PageContainer>
  );
}
