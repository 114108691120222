import {
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog.tsx";
import { Button } from "@/components/ui/button.tsx";
import { popModal } from "@/modals";
import { Form, FormControl, FormField } from "@/components/ui/form.tsx";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover.tsx";
import { cn } from "@/lib/utils.ts";
import { Check, ChevronDown } from "lucide-react";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command.tsx";
import { useState } from "react";

interface Props {
  actualTenancy: string;
}

const formSchema = z.object({
  tenancy: z.string(),
});

const tenancyList = [
  {
    id: "public",
    name: "Publico",
  },
  {
    id: "private",
    name: "Privado",
  },
];

export default function ChangeTenancyModal({ actualTenancy }: Props) {
  // load user tenancy list from database
  const [open, setOpen] = useState(false);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      tenancy: actualTenancy,
    },
  });

  const submit = form.handleSubmit(async (values) => {
    console.log(values);
  });

  return (
    <DialogContent>
      <DialogHeader>
        <DialogTitle>Alteração de grupo do usuário</DialogTitle>
        <DialogDescription>
          Você se encontra atualmente no grupo <strong>{actualTenancy}</strong>.
          Para alterar o grupo, escolha um grupo diferente abaixo.
        </DialogDescription>
      </DialogHeader>
      <Form {...form}>
        <form onSubmit={submit}>
          <FormField
            control={form.control}
            name={"tenancy"}
            render={({ field }) => (
              <Popover open={open} onOpenChange={setOpen} modal={true}>
                <PopoverTrigger asChild>
                  <FormControl>
                    <Button
                      variant={"outline"}
                      role={"combobox"}
                      className={cn(
                        "w-full justify-between",
                        !field.value && "text-muted-foreground"
                      )}
                    >
                      <span>
                        {field.value
                          ? tenancyList.find((t) => t.id === field.value)?.name
                          : "Selecione um grupo"}
                      </span>
                      <ChevronDown className="ml-2 size-4 shrink-0 opacity-50" />
                    </Button>
                  </FormControl>
                </PopoverTrigger>
                <PopoverContent className={"w-[400px] p-0"}>
                  <Command>
                    <CommandInput placeholder="Procurar grupo" />
                    <CommandList>
                      <CommandEmpty>Nenhum grupo encontrado.</CommandEmpty>
                      <CommandGroup>
                        {tenancyList.map((tenancy) => (
                          <CommandItem
                            value={tenancy.id}
                            key={tenancy.id}
                            onSelect={() => {
                              field.onChange(tenancy.id);
                              setOpen(false);
                            }}
                          >
                            <Check
                              className={cn(
                                "mr-2 size-4",
                                field.value === tenancy.id
                                  ? "opacity-100"
                                  : "opacity-0"
                              )}
                            />
                            <span>{tenancy.name}</span>
                          </CommandItem>
                        ))}
                      </CommandGroup>
                    </CommandList>
                  </Command>
                </PopoverContent>
              </Popover>
            )}
          />
          <DialogFooter className={"mt-6"}>
            <Button
              type={"button"}
              variant="outline"
              onClick={() => popModal("ChangeTenancyModal")}
            >
              Cancelar
            </Button>
            <Button variant="default">Alterar grupo</Button>
          </DialogFooter>
        </form>
      </Form>
    </DialogContent>
  );
}
