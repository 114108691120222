import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card.tsx";
import { Badge } from "@/components/ui/badge.tsx";
import {
  Avatar,
  AvatarFallback,
  AvatarGroup,
  AvatarGroupList,
  AvatarOverflowIndicator,
} from "@/components/ui/avatar.tsx";
import { useNavigate } from "react-router-dom";

export interface BatchCardData {
  id: string;
  name: string;
  status: "queued" | "processing" | "finished";
  type: "service" | "product";
  invoices: {
    total: number;
    completed: number;
    error: number;
  };
  owners: {
    id: string;
    name: string;
    role: "creator" | "member";
  }[];
  createdAt: string;
  updatedAt: string;
}
export function BatchCard({ batchData }: { batchData: BatchCardData }) {
  const createdAt = new Date(batchData.createdAt).toLocaleDateString();
  const lastUpdate = new Date(batchData.updatedAt);
  const processing =
    batchData.invoices.total -
    (batchData.invoices.completed + batchData.invoices.error);
  const navigate = useNavigate();

  const redirectToBatch = () => {
    navigate(`/invoices/batches/${batchData.id}`);
  };

  const owners = batchData.owners.sort((a, b) => {
    if (a.role === "creator") {
      return -1;
    }
    if (b.role === "creator") {
      return 1;
    }
    return 0;
  });

  return (
    <Card
      className={
        "flex flex-col hover:cursor-pointer hover:shadow-md hover:bg-accent/40 relative transition-all duration-300"
      }
      onClick={redirectToBatch}
    >
      <CardHeader>
        <div>
          <div className={"flex items-center justify-between"}>
            <CardTitle className={"text-xl"}>
              {batchData.name.length > 20
                ? `${batchData.name.slice(0, 20)}...`
                : batchData.name}
            </CardTitle>
            <span className={"text-xs text-muted-foreground"}>{createdAt}</span>
          </div>
          <Badge variant={"secondary"} className={"absolute left-3 -top-3"}>
            {batchData.type === "service" ? "Serviços" : "Produtos"}
          </Badge>
        </div>
      </CardHeader>
      <CardContent className={"flex-1"}>
        <div className={"grid grid-cols-2 gap-2"}>
          <div className={"flex flex-col gap-1"}>
            <h4 className={"text-sm font-medium"}>Resultados</h4>
            <p className={"text-xs text-muted-foreground"}>
              Processado:{" "}
              <span className={"font-bold"}>
                {batchData.invoices.completed}
              </span>
            </p>
            {batchData.status === "processing" && (
              <p className={"text-xs text-muted-foreground"}>
                Em processamento:{" "}
                <span className={"font-bold"}>{processing}</span>
              </p>
            )}
            <p className={"text-xs text-muted-foreground"}>
              Com erro:{" "}
              <span className={"font-bold"}>{batchData.invoices.error}</span>
            </p>
            <p className={"text-xs text-muted-foreground"}>
              Total:{" "}
              <span className={"font-bold"}>{batchData.invoices.total}</span>
            </p>
          </div>
          <div className={"flex flex-col gap-1"}>
            <h4 className={"text-sm font-medium"}>Responsáveis</h4>
            <AvatarGroup limit={3}>
              <AvatarGroupList>
                {owners.map((owner, index) => (
                  <Avatar key={`${owner}-${index}`}>
                    <AvatarFallback>
                      {owner.name.charAt(0).toUpperCase()}
                    </AvatarFallback>
                  </Avatar>
                ))}
              </AvatarGroupList>
              <AvatarOverflowIndicator />
            </AvatarGroup>
          </div>
        </div>
      </CardContent>
      <CardFooter>
        <span className={"text-xs text-muted-foreground"}>
          Última atualização em {lastUpdate.toLocaleDateString()} às{" "}
          {lastUpdate.toLocaleTimeString()}
        </span>
      </CardFooter>
    </Card>
  );
}
