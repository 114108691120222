import { useQuery } from "@tanstack/react-query";
import type { CompanyWithAddress } from "@shared/schemas/company.ts";
import { DataTable, useGenericTable } from "@/components/table/data-table.tsx";
import { companyColumns } from "@/pages/companies/components/company-columns.tsx";
import { DataTablePagination } from "@/components/table/data-table-pagination.tsx";
import {
  CardButton,
  CardButtonContent,
  CardButtonDescription,
  CardButtonIcon,
} from "@/components/card-button.tsx";
import { Building } from "@phosphor-icons/react";
import {
  PageContainer,
  PageContent,
  PageDescription,
  PageHeader,
  PageTitle,
} from "@/components/layout/page.tsx";

export function CompaniesView() {
  const { data } = useQuery<{
    companies: CompanyWithAddress[];
  }>({
    queryKey: ["companies"],
    refetchInterval: 60000,
  });

  const table = useGenericTable({
    columns: companyColumns,
    data: data?.companies,
  });

  return (
    <PageContainer>
      <PageHeader>
        <PageTitle>Empresas</PageTitle>
        <PageDescription>
          Gerencie todas as empresas que estão cadastradas no sistema.
        </PageDescription>
      </PageHeader>
      <PageContent>
        <div className="space-y-4">
          <CardButton className="w-fit" navigateTo="/companies/create">
            <CardButtonContent>
              <CardButtonIcon>
                <Building size={20} weight="fill" />
              </CardButtonIcon>
              <CardButtonDescription>Nova empresa</CardButtonDescription>
            </CardButtonContent>
          </CardButton>
          <div className="space-y-4 mt-4">
            <DataTable table={table} />
            <DataTablePagination table={table} />
          </div>
        </div>
      </PageContent>
    </PageContainer>
  );
}
