import { Button } from "@/components/ui/button.tsx";
import { Link } from "react-router-dom";
import { Bug } from "@phosphor-icons/react";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion.tsx";

export function HandlePageException({ error }: { error: any }) {
  return (
    <div
      className={"flex flex-col items-center justify-center h-screen gap-y-4"}
    >
      <picture>
        <source
          srcSet="/logo/twokei_black.png"
          media="(prefers-color-scheme: dark)"
        />
        <img
          src="/logo/twokei_white.png"
          alt="Twokei Logo"
          className="h-auto w-64"
        />
      </picture>
      <div className={"text-center flex flex-col space-y-2 max-w-lg text-wrap"}>
        <h1 className={"text-2xl font-medium tracking-widest"}>
          <Bug className={"inline-block mr-2 text-destructive"} />
          Ops... Algo deu errado!
        </h1>
        <p className={"text-sm text-muted-foreground"}>
          Houve um problema ao processar as informações da sua página. Nossa
          equipe já foi notificada e vamos tentar corrigir o problema o mais
          rápido possível.
        </p>
        <Button variant={"outline"} asChild>
          <Link to={"/"} className={"btn btn-primary"}>
            Voltar para a página inicial
          </Link>
        </Button>
      </div>

      {!import.meta.env.PROD && (
        <Accordion type="single" collapsible className="w-2/4">
          <AccordionItem value="error-stack">
            <AccordionTrigger>Stacktrace</AccordionTrigger>
            <AccordionContent>
              <p className={"text-sm text-muted-foreground"}>{error.message}</p>
              {error.stack?.split("\n").map((line: any, index: number) => (
                <p key={index} className={"text-sm text-muted-foreground"}>
                  {line}
                </p>
              ))}
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      )}
    </div>
  );
}
