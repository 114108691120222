import {
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog.tsx";
import { Button } from "@/components/ui/button.tsx";
import { popModal } from "@/modals/index.tsx";
import { useState } from "react";
import { PendingButton } from "@/components/pending-button.tsx";

interface Props {
  title?: string;
  confirmation?: () => unknown | Promise<unknown>;
  cancel?: () => never | Promise<never>;
}

export default function ConfirmationModal({
  title,
  confirmation,
  cancel,
}: Props) {
  const [isPending, setIsPending] = useState(false);

  const confirmationCb = async () => {
    try {
      setIsPending(true);
      await new Promise((resolve) => setTimeout(resolve, 300));
      await confirmation?.();
    } catch (error) {
      console.error(error);
    } finally {
      setIsPending(false);
      popModal("ConfirmationModal");
    }
  };

  const cancelCb = async () => {
    try {
      setIsPending(true);
      await cancel?.();
    } catch (error) {
      console.error(error);
    } finally {
      setIsPending(false);
      popModal("ConfirmationModal");
    }
  };

  return (
    <DialogContent
      onOpenAutoFocus={(e) => e.preventDefault()}
      className={"max-w-md"}
    >
      <DialogHeader>
        <DialogTitle>{title || "Você tem certeza?"}</DialogTitle>
        <DialogDescription>
          A sua confirmação é necessária para prosseguir.
        </DialogDescription>
      </DialogHeader>
      <DialogFooter>
        <Button
          type={"button"}
          onClick={cancelCb}
          variant={"outline"}
          disabled={isPending}
        >
          Não, cancelar
        </Button>
        <PendingButton
          pending={isPending}
          onClick={confirmationCb}
          variant="destructive"
        >
          Sim, confirmar
        </PendingButton>
      </DialogFooter>
    </DialogContent>
  );
}
