import {
  SidebarGroup,
  SidebarGroupContent,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
} from "@/components/ui/sidebar";
import { Link, useLocation } from "react-router-dom";
import { BookOpenText } from "@phosphor-icons/react";

export function NavSecondary() {
  const location = useLocation();

  return (
    <SidebarGroup className="mt-auto">
      <SidebarGroupContent>
        <SidebarMenu>
          {/*<SidebarMenuItem>*/}
          {/*  <SidebarMenuButton className="[&>svg]:size-5 text-sm" size="sm">*/}
          {/*    <Lifebuoy className="text-blue-300" />*/}
          {/*    <span>Suporte</span>*/}
          {/*  </SidebarMenuButton>*/}
          {/*</SidebarMenuItem>*/}
          {/*<SidebarMenuItem>*/}
          {/*  <SidebarMenuButton className="[&>svg]:size-5 text-sm" size="sm">*/}
          {/*    <HandHeart weight="duotone" className="text-rose-400" />*/}
          {/*    <span>Feedback</span>*/}
          {/*  </SidebarMenuButton>*/}
          {/*</SidebarMenuItem>*/}
          <SidebarMenuItem>
            <SidebarMenuButton
              asChild={true}
              size="sm"
              isActive={location.pathname.startsWith("/blog")}
              className="[&>svg]:size-5 text-sm"
            >
              <Link to={"/blog"}>
                <BookOpenText weight="fill" className="text-amber-100" />
                <span>Base de conhecimento</span>
              </Link>
            </SidebarMenuButton>
          </SidebarMenuItem>
        </SidebarMenu>
      </SidebarGroupContent>
    </SidebarGroup>
  );
}
