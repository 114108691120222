import { Button } from "@/components/ui/button.tsx";
import type { ComponentProps } from "react";
import { Loader2 } from "lucide-react";

export function PendingButton({
  children,
  pending = false,
  ...props
}: ComponentProps<typeof Button> & { pending?: boolean }) {
  return (
    <Button {...props} disabled={props.disabled || pending}>
      {pending && <Loader2 className="mr-2 animate-spin" />}
      {typeof children === "string" ? <span>{children}</span> : children}
    </Button>
  );
}
