import type { ColumnDef } from "@tanstack/react-table";
import { X } from "lucide-react";
import { DataTableColumnHeader } from "@/components/table/data-table-column-header.tsx";

interface ErrorDataTableProps {
  status: "success" | "error";
  errors: string[];
  line: number;
  csvData: Record<string, string>;
}

export const errorsNfseColumns: ColumnDef<ErrorDataTableProps>[] = [
  {
    accessorKey: "status",
    header: ({ column }) => (
      <DataTableColumnHeader title="Status" column={column} />
    ),
    cell: () => {
      return <X className="ml-2 size-5 text-red-500" />;
    },
  },
  {
    accessorKey: "line",
    header: ({ column }) => (
      <DataTableColumnHeader title="Linha" column={column} />
    ),
    cell: ({ row }) => {
      return <div>{row.original.line}</div>;
    },
  },
  {
    accessorKey: "errors",
    header: ({ column }) => (
      <DataTableColumnHeader title="Erros" column={column} />
    ),
    cell: ({ row }) => {
      return (
        <div>
          {row.original.errors.map((error, index) => (
            <div key={index}>{error}</div>
          ))}
        </div>
      );
    },
  },
];
