import { useCreateCompanyStore } from "@/pages/companies/create/store/useCreateCompanyStore.ts";
import { Button } from "@/components/ui/button.tsx";
import { CreateCompanyBasic } from "@/pages/companies/create/steps/create-company-basic.tsx";
import { CreateCompanyAddress } from "@/pages/companies/create/steps/create-company-address.tsx";
import { CreateCompanyReview } from "@/pages/companies/create/steps/create-company-review.tsx";
import { ArrowLeft, ArrowRight } from "lucide-react";
import { cn } from "@/lib/utils.ts";
import { Separator } from "@/components/ui/separator.tsx";
import { CreateCompanyTributes } from "@/pages/companies/create/steps/create-company-tributes.tsx";
import {
  PageContainer,
  PageContent,
  PageDescription,
  PageHeader,
  PageTitle,
} from "@/components/layout/page.tsx";

const stepComponents = {
  basicInfo: {
    label: "1. Informações básicas",
    component: CreateCompanyBasic,
  },
  tributes: {
    label: "2. Tributos",
    component: CreateCompanyTributes,
  },
  address: {
    label: "3. Endereço",
    component: CreateCompanyAddress,
  },
  finish: {
    label: "4. Revisão",
    component: CreateCompanyReview,
  },
} as const;

export function CreateCompany() {
  const { step: currentStep, prevStep } = useCreateCompanyStore();

  const { component: CurrentStepComponent } = stepComponents[currentStep];

  const isFirstStep = currentStep === "basicInfo";
  const isLastStep = currentStep === "finish";

  return (
    <PageContainer>
      <PageHeader>
        <PageTitle>Cadastro de empresa</PageTitle>
        <PageDescription>
          Preencha os dados da sua empresa para começar a usufruir das
          funcionalidades oferecidas pelo Twokei.
        </PageDescription>
      </PageHeader>
      <PageContent>
        <div className="p-6 bg-card shadow-card flex justify-center">
          <div className="flex flex-col w-full lg:w-3/4 border rounded p-6 gap-4 min-h-[32rem]">
            <div className="flex justify-evenly gap-4 md:flex-row flex-col">
              {Object.entries(stepComponents).map(([step, { label }]) => (
                <Button
                  key={step}
                  variant={currentStep === step ? "default" : "outline"}
                  className="w-full cursor-default"
                >
                  {label}
                </Button>
              ))}
            </div>

            <CurrentStepComponent className="flex flex-col flex-1">
              <Separator className="my-4" />
              <div
                className={cn(
                  "flex items-center justify-between rounded w-full",
                  isFirstStep && "justify-end"
                )}
              >
                {!isFirstStep && (
                  <Button
                    className="group relative"
                    variant="outline"
                    onClick={prevStep}
                    type="button"
                  >
                    <div className="ml-1 transition group-hover:-translate-x-1">
                      <ArrowLeft />
                    </div>
                    <span>Voltar etapa</span>
                  </Button>
                )}

                {!isLastStep && (
                  <Button className="group relative">
                    <span>Próxima etapa</span>
                    <div className="ml-1 transition group-hover:translate-x-1">
                      <ArrowRight />
                    </div>
                  </Button>
                )}
              </div>
            </CurrentStepComponent>
          </div>
        </div>
      </PageContent>
    </PageContainer>
  );
}
