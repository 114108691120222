import { SidebarInset, SidebarTrigger } from "@/components/ui/sidebar.tsx";
import { cn } from "@/lib/utils.ts";
import { Link, type UIMatch, useMatches } from "react-router-dom";
import { Slash } from "lucide-react";
import { House } from "@phosphor-icons/react";
import { Separator } from "@/components/ui/separator.tsx";
import { useIsMobile } from "@/hooks/use-mobile.tsx";

export function PageContainer({ children }: { children: React.ReactNode }) {
  return (
    <SidebarInset className="gap-4 px-4 py-4 sm:px-6 lg:px-8 overflow-auto">
      {children}
    </SidebarInset>
  );
}

export function PageHeader({ children }: { children: React.ReactNode }) {
  const isMobile = useIsMobile();

  return (
    <header className="flex items-center gap-2">
      {isMobile && (
        <>
          <SidebarTrigger className="-ml-1" />
          <Separator orientation="vertical" className="mr-2 h-4" />
        </>
      )}
      <div className="flex-1 flex flex-col items-start sm:flex-row sm:justify-between sm:items-center border-b border-border pb-2">
        {children}
      </div>
    </header>
  );
}

export function PageTitle({ children }: { children: React.ReactNode }) {
  return <h1 className="text-xl md:text-2xl">{children}</h1>;
}

export function PageDescription({ children }: { children: React.ReactNode }) {
  return <p className="text-sm sm:text-md text-muted-foreground">{children}</p>;
}

interface BreadcrumbMatch extends UIMatch {
  handle: {
    crumb?: string;
  };
}

export function PageBreadcrumbs() {
  const matches = useMatches() as BreadcrumbMatch[];

  const crumbs = matches
    .filter((match) => match.handle?.crumb)
    .map((match, i, arr) => {
      return (
        <div key={match.pathname} className="flex items-center gap-1">
          <Slash className="size-2" />
          {i !== arr.length - 1 ? (
            <Link to={match.pathname}>{match.handle?.crumb}</Link>
          ) : (
            <span>{match.handle.crumb}</span>
          )}
        </div>
      );
    });

  return (
    <div className="flex items-center text-xs text-muted-foreground gap-1">
      Você está em:{" "}
      <Link to="/">
        <House className="h-4 w-4" weight="fill" />
      </Link>
      {crumbs}
    </div>
  );
}

export function PageContent({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) {
  return (
    <>
      <PageBreadcrumbs />
      <div className={cn(className, "flex-1")}>{children}</div>
    </>
  );
}
