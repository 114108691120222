import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "@/components/ui/card.tsx";
import type { serviceInvoiceSchema } from "@shared/schemas/invoices/service.ts";
import type { UseFormReturn } from "react-hook-form";
import type { z } from "zod";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from "@/components/ui/form.tsx";
import { CurrencyInput } from "@/components/validators/currency-input.tsx";

export function TaxForm({
  form,
}: {
  form: UseFormReturn<z.infer<typeof serviceInvoiceSchema>>;
}) {
  console.log(form);
  return (
    <Card>
      <CardHeader>
        <CardTitle>Tributos</CardTitle>
      </CardHeader>
      <CardContent>
        <div className={"grid grid-cols-3 gap-2"}>
          <FormField
            control={form.control}
            name="service.pis"
            render={({ field }) => (
              <FormItem>
                <FormLabel>PIS</FormLabel>
                <FormControl>
                  <CurrencyInput {...field} />
                </FormControl>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="service.cofins"
            render={({ field }) => (
              <FormItem>
                <FormLabel>COFINS</FormLabel>
                <FormControl>
                  <CurrencyInput {...field} />
                </FormControl>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="service.inss"
            render={({ field }) => (
              <FormItem>
                <FormLabel>INSS</FormLabel>
                <FormControl>
                  <CurrencyInput {...field} />
                </FormControl>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="service.ir"
            render={({ field }) => (
              <FormItem>
                <FormLabel>IR</FormLabel>
                <FormControl>
                  <CurrencyInput {...field} />
                </FormControl>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="service.csll"
            render={({ field }) => (
              <FormItem>
                <FormLabel>CSLL</FormLabel>
                <FormControl>
                  <CurrencyInput {...field} />
                </FormControl>
              </FormItem>
            )}
          />
        </div>
      </CardContent>
    </Card>
  );
}
