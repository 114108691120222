import {
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog.tsx";
import { Button } from "@/components/ui/button.tsx";
import { AlertTriangle, FileCode, FileText } from "lucide-react";
import { ComponentWithTooltip } from "@/components/component-with-tooltip.tsx";
import { Card, CardContent } from "@/components/ui/card.tsx";
import { ParagraphInfo } from "@/components/paragraph-info.tsx";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table.tsx";
import { useQuery } from "@tanstack/react-query";
import { formatCurrency } from "@/lib/currency.ts";
import type { SelectServiceInvoiceWithCompanies } from "@shared/types/invoices/service.ts";
import { format } from "date-fns";

interface Props {
  id: string;
}
export default function ViewSentProductInvoice({ id }: Props) {
  const { data: invoice } = useQuery<SelectServiceInvoiceWithCompanies>({
    queryKey: [`/invoices/sent/services/${id}`],
    enabled: !!id,
  });

  if (!invoice) {
    return null;
  }

  const issuedAt = invoice.issuedAt
    ? format(new Date(invoice.issuedAt), "dd/MM/yyyy")
    : "-";
  const service = invoice.items?.[0];

  const download = (url: string) => () => {
    window.open(url, "_blank");
  };

  return (
    <>
      <DialogContent
        onOpenAutoFocus={(e) => e.preventDefault()}
        className={"max-w-5xl max-h-[95vh] overflow-y-auto"}
      >
        <DialogHeader className={"font-medium text-lg"}>
          <DialogTitle>
            Visualizando Nota Fiscal de Serviço emitida (NFS-e)
          </DialogTitle>
          <DialogDescription>
            <p className={"text-xs"}>
              Código de verificação:{" "}
              {invoice.details?.verificationCode ?? (
                <span className="text-destructive">Não disponível</span>
              )}
            </p>
            <p className={"text-xs"}>
              Número da nota:{" "}
              {invoice.details?.invoiceNumber ?? (
                <span className="text-destructive">Não disponível</span>
              )}
            </p>
            <p className={"text-xs"}>Data de emissão: {issuedAt}</p>
          </DialogDescription>
        </DialogHeader>

        <div className={"space-y-4"}>
          {!!invoice.errors && invoice.errors.length > 0 && (
            <Card className={"border-destructive text-destructive"}>
              <CardContent>
                <div
                  className={
                    "my-2 text-lg font-medium tracking-wide flex items-center"
                  }
                >
                  <AlertTriangle className={"mr-2"} />
                  Erros reportados
                </div>
                <div>
                  A nota fiscal não foi emitida porque:
                  <ul className={"list-disc list-inside"}>
                    {invoice.errors.map((error) => (
                      <li key={error.code}>
                        Código: {error.code === "unknown" ? "000" : error.code}{" "}
                        - {error.message} - Possível solução: {error.correction}
                      </li>
                    ))}
                  </ul>
                </div>
              </CardContent>
            </Card>
          )}

          <Card>
            <CardContent>
              <div className={"my-3 text-lg font-medium tracking-wide"}>
                Informações
              </div>
              <div className={"grid grid-cols-1 md:grid-cols-2 gap-4"}>
                <div>
                  <p className={"font-semibold"}>Emitente</p>
                  <ParagraphInfo
                    name={"CNPJ"}
                    value={invoice.issuer.document}
                  />
                  <ParagraphInfo
                    name={"Razão Social"}
                    value={invoice.issuer.name}
                  />
                </div>
                <div>
                  <p className={"font-semibold"}>Destinatário</p>
                  <ParagraphInfo
                    name={"CNPJ"}
                    value={invoice.recipient.document}
                  />
                  <ParagraphInfo
                    name={"Razão Social"}
                    value={invoice.recipient.name}
                  />
                </div>
              </div>
            </CardContent>
          </Card>

          <Card>
            <CardContent>
              <div className={"my-3 text-lg font-medium tracking-wide"}>
                Detalhes
              </div>
              <div>
                <ParagraphInfo
                  name={"Alíquota"}
                  value={Number(service?.aliquot).toFixed(2) + " %"}
                />
                <ParagraphInfo
                  name={"ISS (Retenção na fonte)"}
                  value={service?.issRetained ? "Sim" : "Não"}
                />
              </div>
            </CardContent>
          </Card>

          <Card>
            <CardContent>
              <div className={"my-3 text-lg font-medium tracking-wide"}>
                Produtos e Valores
              </div>
              <div>
                <Table>
                  <TableHeader className={"bg-background z-50"}>
                    <TableRow>
                      <TableHead className={"w-[700px]"}>Descrição</TableHead>
                      <TableHead className={"text-right"}>
                        Valor Total
                      </TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    <TableRow className={"odd:bg-muted/20"}>
                      <TableCell>{service?.description}</TableCell>
                      <TableCell className={"text-right"}>
                        {formatCurrency(service?.value, "BRL")}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </div>
            </CardContent>
          </Card>

          {!!invoice.links && (
            <div className={"flex gap-2"}>
              {!!invoice.links.pdf && (
                <ComponentWithTooltip
                  tooltip={"Baixar como PDF"}
                  align={"start"}
                >
                  <Button
                    size={"icon"}
                    variant={"outline"}
                    onClick={download(invoice.links.pdf)}
                  >
                    <FileText />
                  </Button>
                </ComponentWithTooltip>
              )}

              {!!invoice.links.xml && (
                <ComponentWithTooltip
                  tooltip={"Baixar como XML"}
                  align={"start"}
                  side={"right"}
                >
                  <Button
                    size={"icon"}
                    variant={"outline"}
                    onClick={download(invoice.links.xml)}
                  >
                    <FileCode />
                  </Button>
                </ComponentWithTooltip>
              )}
            </div>
          )}
        </div>
      </DialogContent>
    </>
  );
}
