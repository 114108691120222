import { Outlet } from "react-router-dom";
import { useTitle } from "@/hooks/use-title.ts";

function AuthLayout() {
  useTitle("Autenticação");

  return (
    <main className="relative flex h-screen w-full flex-col overflow-hidden">
      <div className="container relative h-full flex-col items-center justify-center grid lg:max-w-none lg:grid-cols-2 lg:px-0">
        <div
          className="relative hidden h-full flex-col bg-muted p-10 text-white lg:flex dark:border-r select-none"
          draggable={false}
        >
          <div className="absolute inset-0 z-[10] bg-zinc-900 opacity-[35%]" />
          <img
            src="/bg.webp"
            alt="Twokei"
            className="absolute inset-0 object-cover w-full h-full filter brightness-80"
          />

          <div className="relative z-20 flex items-center text-lg font-medium">
            <img
              src="/logo/twokei_white.png"
              className="h-auto w-32"
              alt="Twokei Logo"
            />
          </div>
          <div className="z-20 absolute bottom-10 w-full left-0 text-center text-white">
            <div className="rounded-lg backdrop-blur-lg bg-primary/60 mx-12">
              <p className="text-lg p-2">
                O sistema de gestão e emissão de documentos mais completo do
                Brasil.
              </p>
            </div>
          </div>
        </div>

        <div className="lg:p-8">
          <div className="mx-auto flex w-full flex-col justify-center space-y-6 sm:w-[350px]">
            <div className="flex flex-col space-y-2 text-center">
              <h1 className="text-2xl font-semibold tracking-tight">
                Acesse o Sistema
              </h1>
            </div>
            <Outlet />
          </div>
        </div>
      </div>
    </main>
  );
}

export { AuthLayout };
