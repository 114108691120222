import { useSessionContext } from "@/hooks/use-session-context";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { Suspense } from "react";
import { ErrorBoundary } from "@sentry/react";
import { HandlePageException } from "@/components/error-boundary/page-exception.tsx";

export function BaseLayout() {
  return (
    <Suspense
      fallback={
        <div className="flex h-screen items-center justify-center animate-pulse">
          <img src="/twokei.svg" className="h-10" alt="logo" />
          <span className="text-xl">Carregando...</span>
        </div>
      }
    >
      <ErrorBoundary fallback={HandlePageException}>
        <SessionLayout />
      </ErrorBoundary>
    </Suspense>
  );
}

export function SessionLayout() {
  const { isLoggedIn, isLoading } = useSessionContext();
  const location = useLocation();

  if (isLoading) {
    return (
      <div className="flex h-screen items-center justify-center animate-pulse">
        <img src="/twokei.svg" className="h-10" alt="logo" />
        <span className="text-xl">Carregando...</span>
      </div>
    );
  }

  if (!isLoggedIn && !location.pathname.startsWith("/auth")) {
    return <Navigate to="/auth/login" />;
  }

  if (isLoggedIn && location.pathname.startsWith("/auth")) {
    return <Navigate to="/" />;
  }

  return <Outlet />;
}
