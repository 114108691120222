import { z } from "zod";
import { isCNPJ, isCPF } from "validation-br";

const issuerSchema = z.object({
  document: z.string().min(1, "O CNPJ emissor é obrigatório"),
  operationNature: z.string().optional(),
  specialRegimeTributary: z.string().optional(),
  simplesNacionalOptant: z.boolean({
    message: "O optante pelo Simples Nacional é obrigatório",
  }),
  culturalSupporter: z.boolean().optional(),
});

const recipientAddressSchema = z.object({
  street: z.string().min(1, "O endereço é obrigatório"),
  number: z.string().min(1, "O número é obrigatório"),
  complement: z.string().optional(),
  district: z
    .string()
    .min(1, "O bairro é obrigatório, se não houver, informe 'S/N'"),
  state: z.string().min(1, "UF é obrigatório"),
  zipCode: z.string().min(1, "CEP é obrigatório"),
  cityCode: z.string().min(1, "Código da cidade é obrigatório"),
});

const recipientSchema = z.object({
  document: z
    .string()
    .min(1, "O CNPJ do tomador é obrigatório")
    .refine((value) => isCPF(value) || isCNPJ(value), {
      message: "O CNPJ do tomador é inválido",
    }),
  documentType: z.enum(["CPF", "CNPJ"]).default("CNPJ"),
  municipalRegistration: z.string().optional(),
  stateRegistration: z.string().optional(),
  companyName: z
    .string()
    .min(1, "A razão social ou nome completo é obrigatório"),
  email: z.string().optional(),
  cellphone: z.string().optional(),
  address: recipientAddressSchema,
});

const serviceItemSchema = z.object({
  value: z.string().min(1, "O valor do serviço é obrigatório"),
  description: z
    .string({
      message: "A descrição do serviço é obrigatória",
    })
    .min(1, "A descrição do serviço é obrigatório"),
  municipalTaxCode: z.string().optional(),
  item: z.string().min(1, "O item de lei é obrigatório"),
  issRetained: z.boolean({
    message: "É necessário informar se o ISS na fonte é retido",
  }),

  deductions: z.string().optional(),
  pis: z.string().optional(),
  cofins: z.string().optional(),
  ir: z.string().optional(),
  inss: z.string().optional(),
  csll: z.string().optional(),
  issValue: z.string().optional(),
  issValueRetained: z.string().optional(),
  otherRetentions: z.string().optional(),
  baseValue: z.string().optional(),
  aliquot: z.string().optional(),
  unconditionalDiscount: z.string().optional(),
  conditionalDiscount: z.string().optional(),
  cnae: z.string().optional(),
  cityCode: z.string(),
});

export const serviceInvoiceSchema = z.object({
  issuedAt: z.coerce.string().min(1, "A data de emissão é obrigatória"),
  issuer: issuerSchema,
  recipient: recipientSchema,
  service: serviceItemSchema,
});
