import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "@/components/ui/card.tsx";
import type { serviceInvoiceSchema } from "@shared/schemas/invoices/service.ts";
import type { UseFormReturn } from "react-hook-form";
import type { z } from "zod";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form.tsx";
import { Textarea } from "@/components/ui/textarea.tsx";
import { CurrencyInput } from "@/components/validators/currency-input.tsx";
import type { CompanyWithAddress } from "@shared/schemas/company.ts";
import { useExternalCity } from "@/hooks/use-external-city.ts";
import { Autocomplete } from "@/components/autocomplete.tsx";
import { InfoCircledIcon } from "@radix-ui/react-icons";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip.tsx";
import { Input } from "@/components/ui/input.tsx";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select.tsx";
import { Check, ChevronsUpDown, Loader2 } from "lucide-react";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover.tsx";
import { Button } from "@/components/ui/button.tsx";
import { cn } from "@/lib/utils.ts";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command.tsx";
import { useMutation, useQuery } from "@tanstack/react-query";
import { fetchApi } from "@/lib/api.ts";

type Cities = {
  cities: Array<{ name: string; code: string }>;
};

export function ServiceForm({
  form,
  companies,
}: {
  form: UseFormReturn<z.infer<typeof serviceInvoiceSchema>>;
  companies?: CompanyWithAddress[];
}) {
  const companyCityCode = companies?.find(
    (company) => company.document === form.getValues("issuer.document")
  )?.address.cityCode;

  const { data: externalCity } = useExternalCity({
    cityCode: companyCityCode ?? "",
    includeServiceList: true,
  });

  const { data: dataStates, isLoading: isLoadingStates } = useQuery<{
    states: Array<{ name: string; code: string }>;
  }>({
    queryKey: ["/locations/states"],
  });

  const {
    data: serviceStateCities,
    mutate: mutateCities,
    isPending: isLoadingCities,
  } = useMutation({
    mutationKey: ["/external/state/cities"],
    mutationFn: (state: string) =>
      fetchApi
        .get(`/locations/states/${state}/cities`)
        .then((res) => res.data.data as Cities),
  });

  const serviceList = () => {
    if (!externalCity) {
      return [];
    }

    return externalCity.serviceList.map((service) => ({
      label: `(${service.code}) ${service.description}`,
      value: service.code,
    }));
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle>Discriminação dos Serviços</CardTitle>
      </CardHeader>
      <CardContent>
        <div className={"grid grid-cols-4 gap-2"}>
          <FormField
            control={form.control}
            name="service.item"
            render={({ field }) => (
              <FormItem className="col-span-4">
                <FormLabel required className="flex items-center gap-x-1">
                  <span>Serviço (item de lei)</span>
                  <TooltipProvider delayDuration={100}>
                    <Tooltip>
                      <TooltipTrigger type="button">
                        <InfoCircledIcon className="h-4 w-4 opacity-50" />
                      </TooltipTrigger>
                      <TooltipContent className="max-w-72 text-muted-foreground">
                        Caso o serviço desejado não esteja listado ou a lista
                        está vazia, informe o código do serviço no campo abaixo,
                        normalmente de acordo com a Lei Complementar 116/2003.
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                </FormLabel>
                <Autocomplete
                  options={serviceList()}
                  onValueChange={(option) => {
                    console.log(option.value);
                    field.onChange(option.value);
                  }}
                  emptyMessage={
                    "Serviço não encontrado. Iremos levar em consideração o que você informar."
                  }
                  setInput={true}
                />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="service.description"
            render={({ field }) => (
              <FormItem className="col-span-4">
                <FormLabel required>Descrição do serviço</FormLabel>
                <FormControl>
                  <Textarea {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <div className="space-y-2">
            <FormLabel required>Estado (UF) de Prestação do Serviço</FormLabel>
            <Select
              onValueChange={(value) => {
                mutateCities(value);
              }}
            >
              <FormControl>
                <SelectTrigger>
                  <SelectValue placeholder="Selecione um estado" />
                </SelectTrigger>
              </FormControl>
              <SelectContent>
                {isLoadingStates ? (
                  <SelectItem value="loading" disabled>
                    Carregando...
                  </SelectItem>
                ) : (
                  !!dataStates &&
                  dataStates.states.map((state, index) => (
                    <SelectItem key={index} value={state.code}>
                      {state.name}
                    </SelectItem>
                  ))
                )}
              </SelectContent>
            </Select>
          </div>
          <FormField
            control={form.control}
            name="service.cityCode"
            render={({ field }) => (
              <FormItem>
                <FormLabel required>Cidade de Prestação do Serviço</FormLabel>
                {isLoadingCities ? (
                  <div className="flex items-center ml-2">
                    <Loader2 className="mt-2 size-6 animate-spin" />
                  </div>
                ) : (
                  <Popover>
                    <PopoverTrigger asChild>
                      <FormControl>
                        <Button
                          variant="outline"
                          role="combobox"
                          size="md"
                          disabled={!serviceStateCities}
                          className={cn(
                            "w-full justify-between",
                            !field.value && "text-muted-foreground"
                          )}
                        >
                          {field.value
                            ? serviceStateCities?.cities.find(
                                (city) => city.code === field.value
                              )?.name
                            : "Escolha a cidade"}
                          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                        </Button>
                      </FormControl>
                    </PopoverTrigger>
                    <PopoverContent className="w-full p-0">
                      <Command>
                        <CommandInput placeholder="Procurar cidade..." />
                        <CommandList>
                          <CommandEmpty>Sem resultados :(</CommandEmpty>
                          <CommandGroup>
                            {serviceStateCities?.cities.map((city) => (
                              <CommandItem
                                value={`${city.code}_${city.name}`}
                                key={city.name}
                                onSelect={() => {
                                  form.setValue("service.cityCode", city.code);
                                }}
                              >
                                <Check
                                  className={cn(
                                    "mr-2 h-4 w-4",
                                    city.code === field.value
                                      ? "opacity-100"
                                      : "opacity-0"
                                  )}
                                />
                                {city.name}
                              </CommandItem>
                            ))}
                          </CommandGroup>
                        </CommandList>
                      </Command>
                    </PopoverContent>
                  </Popover>
                )}
              </FormItem>
            )}
          />
          <div />
          <div />
          <FormField
            control={form.control}
            name="service.value"
            render={({ field }) => (
              <FormItem>
                <FormLabel required>Valor do serviço</FormLabel>
                <FormControl>
                  <CurrencyInput {...field} />
                </FormControl>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="service.deductions"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Deduções</FormLabel>
                <FormControl>
                  <CurrencyInput {...field} />
                </FormControl>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="service.baseValue"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Valor base de cálculo</FormLabel>
                <FormControl>
                  <CurrencyInput {...field} />
                </FormControl>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="service.municipalTaxCode"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Código tributário do serviço</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="service.conditionalDiscount"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Valor desconto condicional</FormLabel>
                <FormControl>
                  <CurrencyInput {...field} />
                </FormControl>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="service.unconditionalDiscount"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Valor desconto incondicional</FormLabel>
                <FormControl>
                  <CurrencyInput {...field} />
                </FormControl>
              </FormItem>
            )}
          />
        </div>
      </CardContent>
    </Card>
  );
}
