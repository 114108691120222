import { SidebarNav } from "@/components/sidebar-nav.tsx";
import { Outlet, useParams } from "react-router-dom";
import { Loader2 } from "lucide-react";
import { useCompany } from "@/hooks/use-company.ts";
import {
  PageContainer,
  PageContent,
  PageDescription,
  PageHeader,
  PageTitle,
} from "@/components/layout/page.tsx";

export function CompanyEditLayout() {
  const { document } = useParams();
  const { data, isError, error } = useCompany(document);

  const sidebarNavItems = [
    { label: "Dados da empresa", href: "edit" },
    { label: "Serviços", href: "services" },
    { label: "Tributação", href: "taxes" },
    { label: "Credenciais", href: "settings" },
  ];

  if (isError) {
    return (
      <PageContainer>
        <PageContent>
          <div className="flex items-center justify-center h-full">
            <p className="text-muted-foreground">{error.message}</p>
          </div>
        </PageContent>
      </PageContainer>
    );
  }

  if (!data) {
    return (
      <PageContainer>
        <PageContent>
          <div className="flex items-center justify-center h-full">
            <Loader2 className="animate-spin h-8 w-8" />
          </div>
        </PageContent>
      </PageContainer>
    );
  }

  return (
    <PageContainer>
      <PageHeader>
        <PageTitle>{!isError && data.name}</PageTitle>
        <PageDescription>
          Gerencie as informações da sua empresa, tributação, serviços e
          configurações
        </PageDescription>
      </PageHeader>
      <PageContent>
        <div className="flex flex-col space-y-8 lg:flex-row lg:space-x-12 lg:space-y-0">
          <aside className="lg:w-1/5">
            <SidebarNav items={sidebarNavItems} />
          </aside>
          <div className="flex-1 lg:max-w-4xl">
            <Outlet />
          </div>
        </div>
      </PageContent>
    </PageContainer>
  );
}
