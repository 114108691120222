import {
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog.tsx";
import { Button } from "@/components/ui/button.tsx";
import { FileCode, FileText } from "lucide-react";
import { ComponentWithTooltip } from "@/components/component-with-tooltip.tsx";
import { Card, CardContent } from "@/components/ui/card.tsx";
import { ParagraphInfo } from "@/components/paragraph-info.tsx";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table.tsx";
import { ProductHoverCard } from "@/modals/invoice/components/product-hover-card.tsx";
import { ScrollArea } from "@/components/ui/scroll-area.tsx";
import { cn } from "@/lib/utils.ts";
import { useQuery } from "@tanstack/react-query";
import { formatCurrency } from "@/lib/currency.ts";
import { getNfeDestination } from "@shared/schemas/invoices/nfe/shared/general.ts";

interface Props {
  id: string;
}

const MAX_PRODUCT_ROWS = 8;
const BASE_HEIGHT = 150;
const ROW_HEIGHT = 30;
const LARGE_TABLE_HEIGHT = 500;

const calculateTableHeight = (productCount: number): number => {
  return productCount > MAX_PRODUCT_ROWS
    ? LARGE_TABLE_HEIGHT
    : productCount * ROW_HEIGHT + BASE_HEIGHT;
};

export default function ViewSentProductInvoice({ id }: Props) {
  const { data } = useQuery<any>({
    queryKey: [`/invoices/sent/products/${id}`],
    enabled: !!id,
  });

  if (!data) {
    return null;
  }

  const issuedAt = new Date(data.issuedAt).toLocaleDateString();
  const invoiceType = data.invoiceType === "sale" ? "SAÍDA" : "ENTRADA";

  return (
    <>
      <DialogContent
        onOpenAutoFocus={(e) => e.preventDefault()}
        className={"max-w-5xl max-h-[95vh] overflow-y-auto"}
      >
        <DialogHeader className={"font-medium text-lg"}>
          <DialogTitle>Visualizando Nota Fiscal emitida (NF-e)</DialogTitle>
          <DialogDescription>
            <p className={"text-xs"}>Chave de acesso: {data.externalId}</p>
            <div className={"flex justify-between items-center"}>
              <p className={"text-xs"}>Data de emissão: {issuedAt}</p>
              <p className={"text-xs"}>
                Tipo: {invoiceType}, Número da Nota:{" "}
                {data.details.invoiceNumber}, Série:{" "}
                {data.details.invoiceSeries}
              </p>
            </div>
          </DialogDescription>
        </DialogHeader>

        <div className={"space-y-4"}>
          <Card>
            <CardContent>
              <div className={"my-3 text-lg font-medium tracking-wide"}>
                Informações
              </div>
              <div className={"grid grid-cols-1 md:grid-cols-2 gap-4"}>
                <div>
                  <p className={"font-semibold"}>Emitente</p>
                  <ParagraphInfo name={"CNPJ"} value={data.company.document} />
                  <ParagraphInfo
                    name={"Razão Social"}
                    value={data.company.name}
                  />
                </div>
                <div>
                  <p className={"font-semibold"}>Destinatário</p>
                  <ParagraphInfo name={"CNPJ"} value={data.vendor.document} />
                  <ParagraphInfo
                    name={"Razão Social"}
                    value={data.vendor.name}
                  />
                </div>
              </div>
            </CardContent>
          </Card>

          <Card>
            <CardContent>
              <div className={"my-3 text-lg font-medium tracking-wide"}>
                Detalhes
              </div>
              <div>
                <ParagraphInfo
                  name={"Natureza da Operação"}
                  value={data.details?.operationNature}
                />
                <ParagraphInfo
                  name={"Destino da Operação"}
                  value={
                    data.details?.endConsumer ? "Consumidor final" : "Terceiro"
                  }
                />
                <ParagraphInfo
                  name={"Local do destino"}
                  value={getNfeDestination(data.details.destination)}
                />
              </div>
            </CardContent>
          </Card>

          <Card>
            <CardContent>
              <div className={"my-3 text-lg font-medium tracking-wide"}>
                Produtos e Valores
              </div>
              <div>
                <div className={"grid grid-cols-2"}>
                  <ParagraphInfo
                    name={"Valor total da nota fiscal"}
                    value={formatCurrency(data.details.total, "BRL")}
                  />
                  <ParagraphInfo
                    name={"Frete"}
                    value={formatCurrency(data.details.freight, "BRL")}
                  />
                  <ParagraphInfo
                    name={"Desconto"}
                    value={formatCurrency(data.details.discount, "BRL")}
                  />
                  <ParagraphInfo
                    name={"Seguro"}
                    value={formatCurrency(data.details.insurance, "BRL")}
                  />
                </div>

                {!!data.details && (
                  <ScrollArea
                    className={cn(
                      `h-[${calculateTableHeight(data.details.items.length)}px]`
                    )}
                  >
                    <Table>
                      <TableHeader className={"bg-background z-50"}>
                        <TableRow>
                          <TableHead className={"w-[700px]"}>
                            Descrição
                          </TableHead>
                          <TableHead className={"text-right"}>
                            Valor Total
                          </TableHead>
                        </TableRow>
                      </TableHeader>
                      <TableBody>
                        {/*TYPESCRIPT IS FUCKING SHIT*/}
                        {data.details.items.map((product: any) => (
                          <TableRow
                            key={product.productCode}
                            className={"odd:bg-muted/20"}
                          >
                            <TableCell>
                              <ProductHoverCard
                                align={"start"}
                                side={"top"}
                                sideOffset={15}
                                data={{
                                  ...product,
                                  grossValue: product.grossValue,
                                }}
                              >
                                <span>{product.description}</span>
                              </ProductHoverCard>
                            </TableCell>
                            <TableCell className={"text-right"}>
                              {formatCurrency(product.grossValue, "BRL")}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </ScrollArea>
                )}
              </div>
            </CardContent>
          </Card>

          <div className={"flex gap-2"}>
            <ComponentWithTooltip
              tooltip={"Baixar como PDF (DANFE)"}
              align={"start"}
            >
              <Button size={"icon"} variant={"outline"}>
                <FileText />
              </Button>
            </ComponentWithTooltip>

            <ComponentWithTooltip
              tooltip={"Baixar como XML"}
              align={"start"}
              side={"right"}
            >
              <Button size={"icon"} variant={"outline"}>
                <FileCode />
              </Button>
            </ComponentWithTooltip>
          </div>
        </div>
      </DialogContent>
    </>
  );
}
