import { forwardRef, type HTMLAttributes } from "react";
import { Card } from "@/components/ui/card.tsx";
import { cn } from "@/lib/utils.ts";
import { Badge } from "@/components/ui/badge.tsx";
import { TrendDown, TrendUp } from "@phosphor-icons/react";
import { Skeleton } from "@/components/ui/skeleton.tsx";

const InformativeCard = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <Card
    ref={ref}
    className={cn(
      "flex justify-between p-3 h-full gap-2 min-h-20 shadow-md",
      className
    )}
    {...props}
  />
));

const InformativeCardContent = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div ref={ref} className={cn("flex-1 flex flex-col", className)} {...props} />
));

const InformativeCardIcon = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn(
      "border rounded-md p-1 size-fit flex items-center justify-center",
      className
    )}
    {...props}
  />
));

const InformativeCardTitle = forwardRef<
  HTMLParagraphElement,
  HTMLAttributes<HTMLParagraphElement>
>(({ className, ...props }, ref) => (
  <p
    ref={ref}
    className={cn("text-xs font-semibold text-zinc-700", className)}
    {...props}
  />
));

const InformativeCardValue = forwardRef<
  HTMLParagraphElement,
  HTMLAttributes<HTMLParagraphElement> & { loading?: boolean }
>(({ className, loading, ...props }, ref) => {
  if (loading) {
    return <Skeleton className={cn("w-32 h-8", className)} />;
  }

  return (
    <p
      ref={ref}
      className={cn("font-semibold text-2xl", loading && "animate-pulse")}
      {...props}
    />
  );
});

const InformativeCardBadge = ({
  percentage,
  reverse = false,
}: {
  percentage: number;
  reverse?: boolean;
}) => {
  const positiveColor = reverse ? "text-green-600" : "text-red-600";
  const negativeColor = reverse ? "text-red-600" : "text-green-600";

  return (
    <div className="flex flex-col justify-between">
      <div />
      <Badge
        variant="outline"
        className={cn(
          "flex items-center gap-1",
          percentage > 0 && positiveColor,
          percentage < 0 && negativeColor,
          percentage === 0 && "text-zinc-500"
        )}
      >
        {percentage > 0 && <TrendUp size={16} weight="bold" />}
        {percentage < 0 && <TrendDown size={16} weight="bold" />}
        <span className="font-semibold">
          {Math.abs(percentage).toFixed(2)}%
        </span>
      </Badge>
    </div>
  );
};

export {
  InformativeCard,
  InformativeCardContent,
  InformativeCardIcon,
  InformativeCardTitle,
  InformativeCardValue,
  InformativeCardBadge,
};
