import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card.tsx";
import { Form } from "@/components/ui/form.tsx";
import type { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import type { NfeTaxes } from "@shared/schemas/taxes/nfe-taxes.ts";
import { nfeTaxes } from "@shared/schemas/taxes/nfe-taxes.ts";
import type { NfseTaxes } from "@shared/schemas/taxes/nfse-taxes.ts";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { fetchApi } from "@/lib/api.ts";
import { useParams } from "react-router-dom";
import {
  InputTooltip,
  InputWithTooltip,
} from "@/components/input-with-tooltip.tsx";
import { toast } from "sonner";
import { PendingButton } from "@/components/pending-button.tsx";

type FormEditNfeTaxesType = z.infer<typeof nfeTaxes>;

const inputs = [
  {
    label: "Série",
    name: "invoice.serie",
    title: "Série",
    content: "Informe o número da série para ser cadastrado em nosso sistema.",
  },
  {
    label: "Próximo número",
    name: "invoice.nextNumber",
    title: "Próximo número",
    content:
      "Informe o próximo número que será utilizado para a emissão da NF-e. Este campo não poderá ser alterado após o cadastramento.",
  },
];

export function FormEditNfeTaxes({
  taxes,
}: {
  taxes: { service: NfseTaxes | null; product: NfeTaxes | null };
}) {
  const form = useForm<FormEditNfeTaxesType>({
    resolver: zodResolver(nfeTaxes),
    defaultValues: {
      invoice: {
        serie: "0",
        nextNumber: "0",
      },
      ...(taxes.product ?? {}),
    },
  });

  const { document } = useParams();

  const queryClient = useQueryClient();

  const { mutateAsync, isPending } = useMutation({
    mutationKey: [`/companies/${document}/taxes/product`],
    mutationFn: (values: FormEditNfeTaxesType) => {
      return fetchApi(`/companies/${document}/taxes/product`, {
        method: "POST",
        data: values,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [`/companies/${document}/taxes`],
      });
      toast.success("Configurações salvas com sucesso!");
    },
  });

  return (
    <Card>
      <CardHeader>
        <CardTitle>Configurações da NF-e</CardTitle>
        <CardDescription>
          Faça a configuração do que será aplicado na emissão da NF-e
        </CardDescription>
      </CardHeader>
      <CardContent>
        <Form {...form}>
          <form onSubmit={form.handleSubmit((values) => mutateAsync(values))}>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3">
              {inputs.map((input) => (
                <InputWithTooltip
                  key={input.name}
                  label={input.label}
                  name={input.name}
                  type="number"
                  step="any"
                >
                  <InputTooltip title={input.title} content={input.content} />
                </InputWithTooltip>
              ))}
            </div>
            <div className="flex justify-end mt-4">
              <PendingButton pending={isPending}>
                {isPending ? "Salvando..." : "Salvar"}
              </PendingButton>
            </div>
          </form>
        </Form>
      </CardContent>
    </Card>
  );
}
