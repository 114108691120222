import {
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog.tsx";
import { Button } from "@/components/ui/button.tsx";
import {
  AlertTriangleIcon,
  CheckCheck,
  FileCode,
  FileText,
  X,
} from "lucide-react";
import { ComponentWithTooltip } from "@/components/component-with-tooltip.tsx";
import { Card, CardContent } from "@/components/ui/card.tsx";
import { ParagraphInfo } from "@/components/paragraph-info.tsx";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table.tsx";
import { ProductHoverCard } from "@/modals/invoice/components/product-hover-card.tsx";
import type { SelectProductInvoiceItem } from "@shared/types/invoices/product.ts";
import {
  type SelectProductInvoice,
  type SelectProductInvoiceDetails,
} from "@shared/types/invoices/product.ts";
import { ScrollArea } from "@/components/ui/scroll-area.tsx";
import { useQuery } from "@tanstack/react-query";
import { pushModal } from "@/modals";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert.tsx";
import { formatCurrency } from "@/lib/currency.ts";
import { getNfeDestination } from "@shared/schemas/invoices/nfe/shared/general.ts";
import { Warning } from "@phosphor-icons/react";
import { DownloadTooltip } from "@/components/download-tooltip.tsx";
import { format } from "date-fns";

interface Props {
  id: string;
}

type ApprovalType = {
  userId: string;
  name: string;
  status: string;
  reason: string;
  updatedAt: string;
  createdAt: string;
};

const MAX_PRODUCT_ROWS = 8;
const BASE_HEIGHT = 100;
const ROW_HEIGHT = 10;
const LARGE_TABLE_HEIGHT = 500;

const calculateTableHeight = (productCount: number): number => {
  return productCount > MAX_PRODUCT_ROWS
    ? LARGE_TABLE_HEIGHT
    : productCount * ROW_HEIGHT + BASE_HEIGHT;
};

export default function ViewReceivedProductInvoice({ id }: Props) {
  const { data, isLoading } = useQuery<
    SelectProductInvoice & {
      issuedAt: string;
      details: SelectProductInvoiceDetails | null;
      items: SelectProductInvoiceItem[];
      externalId: string;
      approval: ApprovalType;
      vendor: {
        name: string;
        document: string;
      };
      company: {
        name: string;
        document: string;
      };
      links: {
        pdf: string | null;
        xml: string | null;
      };
    }
  >({
    queryKey: [`/invoices/received/product/${id}`],
  });

  if (!data || isLoading) {
    return null;
  }

  const issuedAt = data.issuedAt
    ? new Date(data.issuedAt).toLocaleDateString()
    : "";
  const invoiceType = data.invoiceType === "sale" ? "SAÍDA" : "ENTRADA";

  const openApprovalModal = (state: "approved" | "rejected") => {
    pushModal("InvoiceManagerDialog", {
      id,
      state,
    });
  };

  return (
    <DialogContent
      onOpenAutoFocus={(e) => e.preventDefault()}
      className={"max-w-5xl max-h-[95vh] overflow-y-auto"}
    >
      <DialogHeader className={"font-medium text-lg"}>
        <DialogTitle>Visualizando Nota Fiscal recebida (NF-e)</DialogTitle>
        <DialogDescription>
          <p className={"text-xs"}>Chave de acesso: {data.externalId}</p>
          <div className={"flex justify-between items-center"}>
            <p className={"text-xs"}>Data de emissão: {issuedAt}</p>
            {!!data.details && (
              <>
                <p className={"text-xs"}>
                  Tipo: {invoiceType}, Número da Nota:{" "}
                  {data.details.invoiceNumber}, Série:{" "}
                  {data.details.invoiceSeries}
                </p>
              </>
            )}
          </div>
        </DialogDescription>
      </DialogHeader>

      <div className={"space-y-4"}>
        {data?.approval?.status === "approved" && (
          <Alert className={`bg-green-800/20 border-green-800/40`}>
            <CheckCheck color="green" />
            <AlertTitle className="inline-flex items-center gap-1 text-green-800">
              <span>
                A nota foi aprovada por{" "}
                <span className="font-bold text-green-900">
                  {data.approval.name}
                </span>{" "}
                em{" "}
                <span className="font-bold">
                  {format(data.approval.updatedAt, "PPp")}
                </span>
                .
              </span>
            </AlertTitle>
            <AlertDescription className="text-green-800">
              {data.approval.reason ||
                "Não foram providenciados detalhes adicionais."}
            </AlertDescription>
          </Alert>
        )}
        {data?.approval?.status === "rejected" && (
          <Alert className={`bg-red-800/20 border-red-800/40`}>
            <AlertTriangleIcon color="darkred" className="size-5" />
            <AlertTitle className="inline-flex items-center gap-1 text-red-800">
              <span>
                A nota foi rejeitada por {data.approval.name} em{" "}
                {format(data.approval.updatedAt, "PPp")}.
              </span>
            </AlertTitle>
            <AlertDescription className="text-red-800">
              {data.approval.reason}
            </AlertDescription>
          </Alert>
        )}

        <Card>
          <CardContent>
            <div className={"my-3 text-lg font-medium tracking-wide"}>
              Informações
            </div>
            <div className={"grid grid-cols-1 md:grid-cols-2 gap-4"}>
              <div>
                <p className={"font-semibold"}>Emitente</p>
                <ParagraphInfo name={"CNPJ"} value={data.vendor.document} />
                <ParagraphInfo name={"Razão Social"} value={data.vendor.name} />
              </div>
              <div>
                <p className={"font-semibold"}>Destinatário</p>
                <ParagraphInfo name={"CNPJ"} value={data.company.document} />
                <ParagraphInfo
                  name={"Razão Social"}
                  value={data.company.name}
                />
              </div>
            </div>
          </CardContent>
        </Card>

        {!!data.details && (
          <Card>
            <CardContent>
              <div className={"my-3 text-lg font-medium tracking-wide"}>
                Detalhes
              </div>
              <div>
                <ParagraphInfo
                  name={"Natureza da Operação"}
                  value={data.details?.operationNature}
                />
                <ParagraphInfo
                  name={"Destino da Operação"}
                  value={
                    data.details?.endConsumer ? "Consumidor final" : "Terceiro"
                  }
                />
                <ParagraphInfo
                  name={"Local do destino"}
                  value={getNfeDestination(data.details?.destination)}
                />
              </div>
            </CardContent>
          </Card>
        )}

        <Card>
          {!data.details && (
            <CardContent>
              <div className="mt-3 flex gap-2 items-center">
                <Warning size={30} className={"text-amber-500"} />
                <span>
                  A NF-e está sendo processada. Em breve você será capaz de
                  visualizar os dados.
                </span>
              </div>
            </CardContent>
          )}
          {!!data.details && (
            <CardContent>
              <div className={"my-3 text-lg font-medium tracking-wide"}>
                Produtos e Valores
              </div>
              <div>
                {/*//TODO: Tratar notas canceladas*/}
                <div className={"grid grid-cols-2"}>
                  <ParagraphInfo
                    name={"Valor total da nota fiscal"}
                    value={formatCurrency(data.details.total, "BRL")}
                  />
                  <ParagraphInfo
                    name={"Frete"}
                    value={formatCurrency(data.details.freight, "BRL")}
                  />
                  <ParagraphInfo
                    name={"Desconto"}
                    value={formatCurrency(data.details.discount, "BRL")}
                  />
                  <ParagraphInfo
                    name={"Seguro"}
                    value={formatCurrency(data.details.insurance, "BRL")}
                  />
                </div>
                <ScrollArea
                  style={{
                    height: calculateTableHeight(data.items.length),
                  }}
                >
                  <Table>
                    <TableHeader className={"bg-background z-50"}>
                      <TableRow>
                        <TableHead className={"w-[700px]"}>Descrição</TableHead>
                        <TableHead className={"text-right"}>
                          Valor Total
                        </TableHead>
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {data.items.map((product, index) => (
                        <TableRow
                          key={`${index}_${product.productCode}`}
                          className={"odd:bg-muted/20"}
                        >
                          <TableCell>
                            <ProductHoverCard
                              align={"start"}
                              side={"top"}
                              sideOffset={15}
                              data={product}
                            >
                              <span>{product.description}</span>
                            </ProductHoverCard>
                          </TableCell>
                          <TableCell className={"text-right"}>
                            {formatCurrency(product?.grossValue || 0, "BRL")}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </ScrollArea>
              </div>
            </CardContent>
          )}
        </Card>

        <div className={"flex items-center justify-between"}>
          <div className={"flex gap-2"}>
            {data.links.pdf && (
              <DownloadTooltip
                tooltip={"Baixar como PDF (DANFE)"}
                link={data.links.pdf}
                align="start"
              >
                <Button size={"icon"} variant={"outline"}>
                  <FileText />
                </Button>
              </DownloadTooltip>
            )}

            {data.links.xml && (
              <DownloadTooltip
                tooltip={"Baixar como XML"}
                link={data.links.xml}
              >
                <Button size={"icon"} variant={"outline"}>
                  <FileCode />
                </Button>
              </DownloadTooltip>
            )}
          </div>
          <div className={"flex gap-2"}>
            <ComponentWithTooltip
              align={"center"}
              side={"left"}
              tooltip={
                "A recusa da nota irá gerar um manifesto de Desconhecimento da Operação. Será necessário justificar."
              }
            >
              <Button
                className={"bg-destructive hover:bg-destructive/90"}
                onClick={() => openApprovalModal("rejected")}
              >
                <X className={"size-4 mr-2"} />
                Rejeitar
              </Button>
            </ComponentWithTooltip>
            <Button
              className={"bg-primary hover:bg-primary/90"}
              onClick={() => openApprovalModal("approved")}
            >
              <CheckCheck className={"size-4 mr-2"} />
              Aprovar
            </Button>
          </div>
        </div>
      </div>
    </DialogContent>
  );
}
