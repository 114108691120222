import React from "react";
import ReactDOM from "react-dom/client";
import { App } from "./App.tsx";
import "./index.css";
import { Toaster } from "@/components/ui/sonner.tsx";
import * as Sentry from "@sentry/react";
import "@fontsource-variable/raleway";

if (import.meta.env.PROD) {
  Sentry.init({
    dsn: "https://70a291f9454776672ae6d643e8277e6f@o4507776853999616.ingest.us.sentry.io/4507776859111424",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.browserProfilingIntegration(),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0,
    profilesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <App />
    <Toaster />
  </React.StrictMode>
);
