import { Card } from "@/components/ui/card.tsx";
import { Plus } from "@phosphor-icons/react";
import { cn } from "@/lib/utils.ts";
import { forwardRef, type HTMLAttributes } from "react";
import { Link } from "react-router-dom";

const CardButton = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement> & { navigateTo?: string; disabled?: boolean }
>(({ className, children, navigateTo, disabled, ...props }, ref) => (
  <Card
    ref={ref}
    className={cn(
      "hover:shadow-md hover:cursor-pointer hover:bg-secondary/30 transition-all duration-200 min-w-60 h-24 select-none",
      disabled && "hover:cursor-not-allowed opacity-50",
      className
    )}
    {...props}
  >
    {navigateTo && !disabled ? (
      <Link to={navigateTo}>{children}</Link>
    ) : (
      children
    )}
  </Card>
));

const CardButtonContent = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement>
>(({ className, children, ...props }, ref) => (
  <div ref={ref} className={cn("relative h-full", className)} {...props}>
    <Plus className="absolute top-3 right-4" />
    <div className="flex flex-col justify-between h-full py-3 px-4">
      {children}
    </div>
  </div>
));

const CardButtonIcon = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement>
>(({ className, children, ...props }, ref) => (
  <div
    ref={ref}
    className={cn(
      "bg-primary text-white p-2 rounded size-10 flex justify-center items-center",
      className
    )}
    {...props}
  >
    {children}
  </div>
));

const CardButtonDescription = forwardRef<
  HTMLParagraphElement,
  HTMLAttributes<HTMLParagraphElement>
>(({ className, children, ...props }, ref) => (
  <p ref={ref} className={cn("text-base font-semibold", className)} {...props}>
    {children}
  </p>
));

export { CardButton, CardButtonContent, CardButtonIcon, CardButtonDescription };
