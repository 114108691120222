export const formatCurrency = (
  value: number | string | undefined,
  currency: string
) => {
  if (!value) {
    value = 0;
  }

  if (typeof value === "string") {
    value = parseFloat(value);
  }

  return new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency,
  }).format(value);
};
