import * as React from "react";

import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form.tsx";
import { cn } from "@/lib/utils.ts";
import { Input } from "@/components/ui/input.tsx";
import { fetchApi } from "@/lib/api.ts";
import { useMutation } from "@tanstack/react-query";
import { useLocalStorage } from "@/hooks/use-local-storage.ts";
import { useNavigate } from "react-router-dom";
import { PendingButton } from "@/components/pending-button.tsx";

interface UserAuthFormProps extends React.HTMLAttributes<HTMLDivElement> {}

const formSchema = z.object({
  email: z
    .string({
      required_error: "O email é obrigatório",
    })
    .email("O email informado é inválido"),
});

export function UserAuthForm({ className, ...props }: UserAuthFormProps) {
  const [, setPreAuthSessionId] = useLocalStorage<string | null>(
    "preAuthSessionId",
    null
  );
  const [lastEmail, setLastEmail] = useLocalStorage<string | null>(
    "lastEmail",
    null
  );

  const navigate = useNavigate();

  const { mutate, isPending } = useMutation({
    mutationKey: ["/auth/code/request"],
    mutationFn: async (data: z.infer<typeof formSchema>) => {
      return fetchApi.post<{
        data: {
          flow: string;
          preAuthSessionId: string;
        };
      }>("/auth/code/request", data);
    },
    onSuccess: (response) => {
      const { flow, preAuthSessionId } = response.data.data;

      if (flow === "INPUT_CODE_AND_MAGIC_LINK") {
        setPreAuthSessionId(preAuthSessionId);
        navigate("/auth/code");
      }
    },
  });

  const authForm = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: lastEmail ?? "",
    },
  });

  const onSubmitForm = async (data: z.infer<typeof formSchema>) => {
    setLastEmail(data.email);
    mutate(data);
  };

  return (
    <Form {...authForm}>
      <form onSubmit={authForm.handleSubmit(onSubmitForm)}>
        <div className={cn("grid gap-6", className)} {...props}>
          <div className="grid gap-2">
            <div className="grid gap-1">
              <FormField
                name="email"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Digite o seu email</FormLabel>
                    <FormControl>
                      <Input
                        {...field}
                        placeholder="nome@dominio.com.br"
                        autoComplete="email"
                        autoCorrect="off"
                      />
                    </FormControl>
                    <FormDescription>
                      <span className="text-xs text-gray-500">
                        Um email com o link de acesso será enviado
                      </span>
                    </FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <PendingButton pending={isPending}>Solicitar acesso</PendingButton>
          </div>
        </div>
      </form>
    </Form>
  );
}
