import { Outlet } from "react-router-dom";
import { useTitle } from "@/hooks/use-title.ts";
import { SidebarProvider } from "@/components/ui/sidebar.tsx";
import { AppSidebar } from "@/components/app-sidebar.tsx";
import { Suspense } from "react";
import { PageContainer, PageContent } from "@/components/layout/page.tsx";

export function Layout() {
  useTitle("Sistema");

  return (
    <SidebarProvider>
      <AppSidebar />

      <Suspense
        fallback={
          <PageContainer>
            <PageContent className="size-full flex items-center justify-center">
              <img src="/twokei.svg" className="h-10" alt="logo" />
              <span className="text-xl">Carregando...</span>
            </PageContent>
          </PageContainer>
        }
      >
        <Outlet />
      </Suspense>
    </SidebarProvider>
  );
}
