import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { inputVendorSchema } from "@shared/schemas/vendor.ts";
import { useExternalCompany } from "@/hooks/use-external-company.ts";
import { useEffect } from "react";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form.tsx";
import { Input } from "@/components/ui/input.tsx";
import { formatCNPJ, onlyNumbers } from "@shared/format.ts";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { fetchApi } from "@/lib/api.ts";
import type { z } from "zod";
import { useNavigate } from "react-router-dom";
import { PendingButton } from "@/components/pending-button.tsx";
import {
  PageContainer,
  PageContent,
  PageDescription,
  PageHeader,
  PageTitle,
} from "@/components/layout/page.tsx";

type FormFields = z.infer<typeof inputVendorSchema>;

export function CreateNewVendor() {
  const form = useForm<FormFields>({
    resolver: zodResolver(inputVendorSchema),
    mode: "onBlur",
  });

  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const { mutate, isPending } = useMutation({
    mutationKey: ["/vendors"],
    mutationFn: (data: FormFields) =>
      fetchApi("/vendors", {
        method: "post",
        data: data,
      }),
    onSuccess: async () => {
      form.reset();

      await queryClient.invalidateQueries({
        queryKey: ["/vendors"],
      });

      navigate("/vendors");
    },
  });

  const document = form.watch("document");

  const { data: externalCompany, isLoading: isFetchingExternalCompany } =
    useExternalCompany(document);

  useEffect(() => {
    if (isFetchingExternalCompany || !document || !externalCompany) {
      return;
    }

    form.setValue("name", externalCompany.name);
    form.setValue("fantasyName", externalCompany.fantasyName);
    form.setValue("document", externalCompany.document);
  }, [document, externalCompany, form, isFetchingExternalCompany]);

  return (
    <PageContainer>
      <PageHeader>
        <PageTitle>Novo fornecedor</PageTitle>
        <PageDescription>
          Adicione um novo fornecedor a partir de um CNPJ ou CPF válido.
        </PageDescription>
      </PageHeader>
      <PageContent>
        <div className="p-6 bg-card shadow-card flex justify-center">
          <div className="flex flex-col w-full lg:w-3/4 border rounded p-6 gap-4 min-h-[32rem]">
            <Form {...form}>
              <form
                className="flex flex-col gap-4"
                onSubmit={form.handleSubmit((data) => mutate(data))}
              >
                <FormField
                  name="document"
                  render={({ field: { value, onChange, ...rest } }) => (
                    <FormItem>
                      <FormLabel className="inline-flex gap-2 items-center">
                        CNPJ
                      </FormLabel>
                      <FormControl>
                        <Input
                          {...rest}
                          value={formatCNPJ(value)}
                          onChange={(e) =>
                            onChange(onlyNumbers(e.target.value))
                          }
                          placeholder="CNPJ"
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                {[
                  {
                    id: "name",
                    title: "Nome da empresa",
                  },
                  {
                    id: "fantasyName",
                    title: "Nome fantasia",
                  },
                  {
                    id: "email",
                    title: "Email",
                  },
                  {
                    id: "phone",
                    title: "Telefone",
                  },
                ].map(({ id, title }) => (
                  <FormField
                    key={id}
                    name={id}
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>{title}</FormLabel>
                        <FormControl>
                          <Input {...field} placeholder={title} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                ))}
                <PendingButton pending={isPending} className="w-1/3 ml-auto">
                  {isPending ? "Adicionando..." : "Adicionar Fornecedor"}
                </PendingButton>
              </form>
            </Form>
          </div>
        </div>
      </PageContent>
    </PageContainer>
  );
}
