import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card.tsx";
import { Separator } from "@/components/ui/separator.tsx";
import { useNfeStore } from "@/pages/invoices/product/store/useNfeStore.ts";
import {
  Table,
  TableBody,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table.tsx";
import {
  NfeDocumentDestination,
  NfeDocumentType,
} from "@shared/schemas/invoices/nfe/shared/general.ts";
import { Textarea } from "@/components/ui/textarea.tsx";
import { useMutation } from "@tanstack/react-query";
import { fetchApi } from "@/lib/api.ts";
import type { CreateProductInvoice } from "@shared/schemas/invoices/product.ts";
import { Link, useNavigate } from "react-router-dom";
import { LucideLink } from "lucide-react";
import { toast } from "sonner";
import { formatCurrency } from "@/lib/currency.ts";
import { PendingButton } from "@/components/pending-button.tsx";

export function NfeReview() {
  const navigate = useNavigate();

  const { nfeData, setValues, resetStore } = useNfeStore((state) => ({
    nfeData: state.nfeData,
    setValues: state.setValuesSchema,
    resetStore: state.reset,
  }));

  const { mutateAsync, isPending } = useMutation({
    mutationKey: ["/invoices/sent/products"],
    mutationFn: async (data: CreateProductInvoice) => {
      return fetchApi(`/invoices/sent/products`, {
        method: "POST",
        data,
      });
    },
    onSuccess: () => {
      resetStore();
      toast.success(
        "Sua nota fiscal foi enviada para a fila de processamento."
      );
      navigate("/invoices/product/sent");
    },
  });

  const submitNfe = () => {
    setValues({
      ...nfeData.values,
      insurance: "0",
      freight: "0",
      freightType: 9,
      discount: "0",
    });
    mutateAsync(nfeData);
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle>Validação e Transmissão</CardTitle>
        <CardDescription>
          Revise as informações inseridas antes de realizar a transmissão da sua
          nota fiscal para o SEFAZ. Se a sua nota fiscal estiver correta, clique
          no botão "Transmitir". O status da sua nota fiscal será atualizada
          automaticamente na tabela de{" "}
          <Link to={"/invoices/product/sent"}>
            <span className={"text-primary underline"}>
              Notas Fiscais Emitidas
            </span>
            <LucideLink className={"ml-1 inline-block"} size={16} />
          </Link>
          .
        </CardDescription>
      </CardHeader>
      <CardContent className="space-y-4">
        <div>
          <span className="font-semibold tracking-wide">
            Informações Gerais
          </span>
          <Separator />
          <div className="grid grid-cols-5 gap-3">
            <div className="col-span-3">
              <span className="text-muted-foreground font-medium text-xs">
                Emitente
              </span>
              <p>
                {nfeData.issuer.name || "Não informado"} (
                {nfeData.issuer.document || "N/A"})
              </p>
            </div>
            <div className="col-span-2">
              <span className="text-muted-foreground font-medium text-xs">
                Destinatário
              </span>
              <p>
                {nfeData.recipient.name || "Não informado"} (
                {nfeData.recipient.document || "N/A"})
              </p>
            </div>
            <div className="col-span-5">
              <span className="text-muted-foreground font-medium text-xs">
                Natureza da Operação
              </span>
              <p>{nfeData.invoice.operationNature || "Não especificado"}</p>
            </div>
            <div className="col-span-1">
              <span className="text-muted-foreground font-medium text-xs">
                Consumidor Final
              </span>
              <p>{nfeData.invoice.endConsumer ? "Sim" : "Não"}</p>
            </div>
            <div className="col-span-1">
              <span className="text-muted-foreground font-medium text-xs">
                Tipo da Nota Fiscal
              </span>
              <p>
                {nfeData.invoice.documentType ==
                NfeDocumentType.NOTA_FISCAL_ENTRADA
                  ? "Entrada"
                  : "Saída"}
              </p>
            </div>
            <div className="col-span-1">
              <span className="text-muted-foreground font-medium text-xs">
                Data de emissão
              </span>
              <p>
                {new Date(nfeData.invoice.issuedAt).toLocaleDateString() ||
                  "Não informado"}
              </p>
            </div>
            <div className="col-span-1">
              <span className="text-muted-foreground font-medium text-xs">
                Data de saída/entrada
              </span>
              <p>
                {new Date(nfeData.invoice.issuedAt).toLocaleDateString() ||
                  "Não informado"}
              </p>
            </div>
            <div className="col-span-1">
              <span className="text-muted-foreground font-medium text-xs">
                Destino da Operação
              </span>
              <p>
                {nfeData.invoice.destination ==
                NfeDocumentDestination.OPERACAO_INTERNA
                  ? "Operação Interna"
                  : nfeData.invoice.destination ==
                      NfeDocumentDestination.OPERACAO_INTERESTADUAL
                    ? "Operação Interestadual"
                    : "Operação com Exterior"}
              </p>
            </div>
          </div>
        </div>
        <div>
          <span className="font-semibold tracking-wide">Produtos</span>
          <Separator />
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Código</TableHead>
                <TableHead>Nome</TableHead>
                <TableHead>CFOP</TableHead>
                <TableHead>Un.</TableHead>
                <TableHead>Qtde</TableHead>
                <TableHead>Unit</TableHead>
                <TableHead>Total</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {nfeData.items.map((item, index) => (
                <TableRow key={index}>
                  <td>{item.productCode}</td>
                  <td>{item.description}</td>
                  <td>{item.cfop}</td>
                  <td>{item.commercialUnit}</td>
                  <td>{item.commercialQuantity}</td>
                  <td>{formatCurrency(item.commercialUnitValue, "BRL")}</td>
                  <td>{formatCurrency(item.grossValue ?? 0, "BRL")}</td>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
        <div>
          <span className="font-semibold tracking-wide">Transporte</span>
          <Separator />
          <div className="grid grid-cols-5">
            <div className="col-span-1">
              <span className="text-muted-foreground font-medium text-xs">
                Responsável pelo Transporte
              </span>
              <p>Outros</p>
            </div>
            {/*Mostrar apenas se houver incidência de transporte*/}
            <div className="col-span-1">
              <span className="text-muted-foreground font-medium text-xs">
                Peso Bruto
              </span>
              <p>N/I</p>
            </div>
            <div className="col-span-1">
              <span className="text-muted-foreground font-medium text-xs">
                Peso Líquido
              </span>
              <p>N/I</p>
            </div>
            <div className="col-span-1">
              <span className="text-muted-foreground font-medium text-xs">
                Placa do Veículo
              </span>
              <p>N/I</p>
            </div>
            <div className="col-span-1">
              <span className="text-muted-foreground font-medium text-xs">
                Código ANTT
              </span>
              <p>N/I</p>
            </div>
          </div>
        </div>
        <div>
          <span className="font-semibold tracking-wide">
            Cobrança e Faturas
          </span>
          <Separator />
          <div className="grid grid-cols-5">
            <div className="col-span-1">
              <span className="text-muted-foreground font-medium text-xs">
                Faturas
              </span>
              <p>N/I</p>
            </div>
            <div className="col-span-1">
              <span className="text-muted-foreground font-medium text-xs">
                Cobrança
              </span>
              <p>Boleto</p>
            </div>
          </div>
        </div>
        <div>
          <span className="font-semibold tracking-wide">Dados Adicionais</span>
          <Separator />
          <div className="grid grid-cols-2 gap-x-3">
            <div>
              <span className="text-xs font-medium text-muted-foreground">
                Informações Complementares
              </span>
              <Textarea disabled={true} className="resize-none" />
            </div>
            <div>
              <span className="text-xs font-medium text-muted-foreground">
                Reservado ao fisco
              </span>
              <Textarea disabled={true} className="resize-none" />
            </div>
          </div>
        </div>
        <div className="flex justify-end">
          <PendingButton variant="pop" pending={isPending} onClick={submitNfe}>
            {isPending ? "Transmitindo..." : "Transmitir"}
          </PendingButton>
        </div>
      </CardContent>
    </Card>
  );
}
