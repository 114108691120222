import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card.tsx";
import { Form } from "@/components/ui/form.tsx";
import type { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  InputTooltip,
  InputWithTooltip,
} from "@/components/input-with-tooltip.tsx";
import type { NfseTaxes } from "@shared/schemas/taxes/nfse-taxes.ts";
import { nfseTaxes } from "@shared/schemas/taxes/nfse-taxes.ts";
import type { NfeTaxes } from "@shared/schemas/taxes/nfe-taxes.ts";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { fetchApi } from "@/lib/api.ts";
import { PendingButton } from "@/components/pending-button.tsx";

const inputs = [
  {
    label: "IRPJ",
    name: "irpj",
    title: "Imposto de Renda Pessoa Jurídica (IRPJ)",
    content:
      "Alíquota aplicada sobre o valor de dedução, que poderá ser informado durante a emissão da NFS-e.",
  },
  {
    label: "CSLL",
    name: "csll",
    title: "Contribuição Social sobre o Lucro Líquido (CSLL)",
    content:
      "Alíquota aplicada sobre o valor de dedução, que poderá ser informado durante a emissão da NFS-e.",
  },
  {
    label: "COFINS",
    name: "cofins",
    title: "Contribuição para o Financiamento da Seguridade Social (COFINS)",
    content:
      "Alíquota aplicada sobre o valor de dedução, que poderá ser informado durante a emissão da NFS-e.",
  },
  {
    label: "PIS",
    name: "pis",
    title: "Programa de Integração Social (PIS)",
    content:
      "Alíquota aplicada sobre o valor de dedução, que poderá ser informado durante a emissão da NFS-e.",
  },
  {
    label: "INSS",
    name: "inss",
    title: "Instituto Nacional do Seguro Social (INSS)",
    content:
      "Alíquota aplicada sobre o valor de dedução, que poderá ser informado durante a emissão da NFS-e.",
  },
  {
    label: "ISS",
    name: "iss",
    title: "Imposto sobre Serviços (ISS)",
    content:
      "Alíquota aplicada sobre o valor de dedução, que poderá ser informado durante a emissão da NFS-e.",
  },
  {
    label: "Outros",
    name: "others",
    title: "Outros",
    content:
      "Alíquota aplicada sobre o valor de dedução, que poderá ser informado durante a emissão da NFS-e.",
  },
];

type FormEditNfseTaxesType = z.infer<typeof nfseTaxes>;

export function FormEditNfseTaxes({
  taxes,
}: {
  taxes: { service: NfseTaxes | null; product: NfeTaxes | null };
}) {
  const { document } = useParams();
  const queryClient = useQueryClient();

  const form = useForm<FormEditNfseTaxesType>({
    resolver: zodResolver(nfseTaxes),
    defaultValues: {
      irpj: "0",
      csll: "0",
      cofins: "0",
      pis: "0",
      inss: "0",
      iss: "0",
      others: "0",
      ...(taxes.service ?? {}),
    },
  });

  const { mutateAsync, isPending } = useMutation({
    mutationKey: [`/companies/${document}/taxes/service`],
    mutationFn: (values: FormEditNfseTaxesType) => {
      return fetchApi(`/companies/${document}/taxes/service`, {
        method: "POST",
        data: values,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [`/companies/${document}/taxes`],
      });
    },
  });

  return (
    <Card>
      <CardHeader>
        <CardTitle>Configurar tributos da NFS-e</CardTitle>
        <CardDescription>
          Pré-configure os tributos que serão aplicados na emissão da NFS-e e
          retidos na fonte.
        </CardDescription>
      </CardHeader>
      <CardContent>
        <Form {...form}>
          <form onSubmit={form.handleSubmit((values) => mutateAsync(values))}>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3">
              {inputs.map((input) => (
                <InputWithTooltip
                  key={input.name}
                  label={input.label}
                  name={input.name}
                  type="number"
                  step="any"
                >
                  <InputTooltip title={input.title} content={input.content} />
                </InputWithTooltip>
              ))}
            </div>
            <div className="flex justify-end mt-4">
              <PendingButton pending={isPending}>
                {isPending ? "Salvando..." : "Salvar"}
              </PendingButton>
            </div>
          </form>
        </Form>
      </CardContent>
    </Card>
  );
}
