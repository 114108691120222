import { Outlet } from "react-router-dom";
import {
  PageContainer,
  PageContent,
  PageDescription,
  PageHeader,
  PageTitle,
} from "@/components/layout/page.tsx";

export function InvoicesBatchesLayout() {
  return (
    <PageContainer>
      <PageHeader>
        <PageTitle>Lotes</PageTitle>
        <PageDescription>
          Gerencie todas as notas fiscais em lote que estão na fila para
          processamento, em processamento ou concluídas.
        </PageDescription>
      </PageHeader>
      <PageContent>
        <Outlet />
      </PageContent>
    </PageContainer>
  );
}
