import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card.tsx";
import { Button } from "@/components/ui/button.tsx";
import { EditIcon, Plus, SearchIcon, XIcon } from "lucide-react";
import { Input } from "@/components/ui/input.tsx";
import { Separator } from "@/components/ui/separator.tsx";

import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table.tsx";
import { useNfeStore } from "@/pages/invoices/product/store/useNfeStore.ts";
import { pushModal } from "@/modals";
import { formatCurrency } from "@/lib/currency.ts";

export function NfeItems() {
  const { items, removeProduct } = useNfeStore((state) => ({
    items: state.nfeData.items,
    removeProduct: state.removeItemSchema,
  }));

  return (
    <Card>
      <CardHeader>
        <CardTitle>Produtos</CardTitle>
        <CardDescription>
          Insira os produtos que compõem a nota fiscal
        </CardDescription>
      </CardHeader>
      <CardContent className="space-y-4">
        <div className="inline-flex space-x-3 w-full">
          <Input
            placeholder="Buscar produto... (nome ou código identificador) - desabilitado, utilize o botão de adição"
            disabled={true}
          />
          <Button disabled={true} variant="outline" size="icon">
            <SearchIcon size={16} />
          </Button>
          <Button
            variant="default"
            size="icon"
            onClick={() => pushModal("ProductItemCreate")}
          >
            <Plus size={18} />
          </Button>
        </div>

        <Separator />

        <div>
          <Table>
            <TableCaption>
              Lista dos produtos adicionados à nota fiscal
              {items.length > 0 && (
                <p>
                  Total de produtos: <strong>{items.length}</strong>, Valor
                  total dos produtos:{" "}
                  <strong>
                    {formatCurrency(
                      items.reduce((acc, item) => acc + item.grossValue!, 0),
                      "BRL"
                    )}
                  </strong>
                  , Valor base total dos impostos:{" "}
                  <strong>
                    {formatCurrency(
                      items.reduce((acc, item) => acc + item.taxableValue!, 0),
                      "BRL"
                    )}
                  </strong>
                </p>
              )}
            </TableCaption>
            <TableHeader>
              <TableRow>
                <TableHead>Item</TableHead>
                <TableHead>Código</TableHead>
                <TableHead>Nome</TableHead>
                <TableHead>CFOP</TableHead>
                <TableHead>NCM</TableHead>
                <TableHead>Un.</TableHead>
                <TableHead>Qtde</TableHead>
                <TableHead>Unit</TableHead>
                <TableHead>Vlr Total</TableHead>
                <TableHead>Vlr Bs Imp</TableHead>
                <TableHead></TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {items.map((item, index) => (
                <TableRow key={index}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{item.productCode}</TableCell>
                  <TableCell>{item.description}</TableCell>
                  <TableCell>{item.cfop}</TableCell>
                  <TableCell>{item.ncmCode}</TableCell>
                  <TableCell>{item.commercialUnit}</TableCell>
                  <TableCell>{item.commercialQuantity}</TableCell>
                  <TableCell>
                    {formatCurrency(item.commercialUnitValue, "BRL")}
                  </TableCell>
                  <TableCell>
                    {formatCurrency(item.grossValue ?? 0, "BRL")}
                  </TableCell>
                  <TableCell>
                    {formatCurrency(item.taxableValue ?? 0, "BRL")}
                  </TableCell>
                  <TableCell className="inline-flex space-x-2">
                    <EditIcon size={18} className="cursor-pointer" />
                    <XIcon
                      size={18}
                      className="text-destructive cursor-pointer"
                      onClick={() => removeProduct(item)}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </CardContent>
    </Card>
  );
}
