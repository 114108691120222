import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { Button } from "@/components/ui/button";
import { Separator } from "@/components/ui/separator";
import { ArrowLeft } from "lucide-react";
import { Link, useParams } from "react-router-dom";
import { useSuspenseQuery } from "@tanstack/react-query";
import { cn } from "@/lib/utils.ts";
import { PageContainer, PageContent } from "@/components/layout/page.tsx";

export function BlogPost() {
  const { category, post } = useParams<{ category: string; post: string }>();

  const {
    data: { content, attributes },
  } = useSuspenseQuery({
    queryKey: ["blog", category, post],
    queryFn: async (context) => {
      const [_, category, post] = context.queryKey;

      const result = await import(`./content/${category}/${post}.md`);

      return {
        attributes: result.attributes,
        content: result.html,
      };
    },
  });

  if (!category || !post) {
    return null;
  }

  const headers = content.matchAll(
    /<h([2-6])\s+id="([^"]+)".*?><a[^>]*>([^<]+)<\/a><\/h\1>/g
  );

  const titles = Array.from(headers || [])
    .map((value) => {
      const [level, id, text] = (value as string[]).slice(1);

      return {
        id,
        title: text,
        level: Number(level),
      };
    })
    .filter(Boolean);

  return (
    <PageContainer>
      <PageContent className="mx-auto px-4 pb-8">
        <Button asChild={true} variant="outline" className="mb-4">
          <Link to={"/blog"}>
            <ArrowLeft className="mr-2 h-4 w-4" />
            Voltar
          </Link>
        </Button>
        <article className="space-y-8">
          <div className="space-y-2">
            <h1 className="text-4xl font-extrabold tracking-tight lg:text-5xl lg:leading-[3.5rem]">
              {attributes.title}
            </h1>
            <h3 className="flex items-center text-sm text-muted-foreground">
              {attributes.description}
            </h3>
          </div>
          {attributes.author && (
            <div className="flex items-center space-x-4">
              <Avatar>
                <AvatarImage src="/placeholder-user.jpg" alt="Author" />
                <AvatarFallback>
                  {attributes.author.name.slice(0, 2)}
                </AvatarFallback>
              </Avatar>
              <div>
                <p className="text-sm font-medium">{attributes.author.name}</p>
                <p className="text-sm text-muted-foreground">
                  {attributes.author.description}
                </p>
              </div>
            </div>
          )}
          <Separator />
          <div className="flex gap-8 size-full">
            <aside className="hidden md:block">
              <nav className="space-y-2 sticky top-20">
                <p className="font-bold mb-2">Sumário</p>
                <ul className="space-y-2 text-sm">
                  {titles.map(({ id, title, level }) => (
                    <li key={id}>
                      <a
                        href={`#${id}`}
                        className={cn(
                          "text-muted-foreground hover:text-primary",
                          level === 1 && "pl-0 text-lg",
                          level === 2 && "pl-1 text-base",
                          level === 3 && "pl-2",
                          level === 4 && "pl-4"
                        )}
                      >
                        {title}
                      </a>
                    </li>
                  ))}
                </ul>
              </nav>
            </aside>
            <Separator
              orientation="vertical"
              className="h-80 hidden md:block"
            />
            <main
              className="prose dark:prose-invert space-y-6 [&>h2>a]:no-underline text-balance w-full flex-1 max-w-4xl"
              dangerouslySetInnerHTML={{ __html: content }}
            />
          </div>
        </article>
      </PageContent>
    </PageContainer>
  );
}
