import type { ColumnDef, TableOptions } from "@tanstack/react-table";
import {
  flexRender,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { ErrorBoundaryException } from "@/components/error-boundary/exception.tsx";
import { ErrorBoundary } from "@sentry/react";
import type { ComponentPropsWithoutRef } from "react";
import { cn } from "@/lib/utils.ts";

interface DataTableProps<TData, TValue>
  extends Partial<Omit<TableOptions<TData>, "data" | "columns">> {
  columns: ColumnDef<TData, TValue>[];
  data?: TData[] | null;
}

// eslint-disable-next-line react-refresh/only-export-components
export function useGenericTable<TData, TValue>({
  columns,
  data,
  ...props
}: DataTableProps<TData, TValue>) {
  return useReactTable<TData>({
    data: data ?? [],
    columns,
    enableHiding: false,
    enableRowSelection: true,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    ...props,
  });
}

export function DataTable<TData, TValue>({
  table,
  className,
  ...props
}: {
  table: ReturnType<typeof useGenericTable<TData, TValue>>;
} & ComponentPropsWithoutRef<"div">) {
  const data = table.getSortedRowModel().rows;

  return (
    <ErrorBoundary fallback={ErrorBoundaryException}>
      <div
        className={cn("border rounded-md overflow-x-auto", className)}
        {...props}
      >
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead
                      key={header.id}
                      colSpan={header.colSpan}
                      className={"bg-background"}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {Array.isArray(data) && !table.getRowModel().rows?.length && (
              <TableRow>
                <TableCell colSpan={table.getAllColumns().length}>
                  <div className="flex items-center justify-center p-4 text-center text-gray-500">
                    <span>Nenhum registro encontrado.</span>
                  </div>
                </TableCell>
              </TableRow>
            )}

            {!!table.getRowModel().rows?.length &&
              table.getRowModel().rows.map((row) => (
                <TableRow
                  key={row.id}
                  data-state={row.getIsSelected() && "selected"}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </div>
    </ErrorBoundary>
  );
}
