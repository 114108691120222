import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form.tsx";
import { Input } from "@/components/ui/input.tsx";
import { useParams } from "react-router-dom";
import { useUpdateCredentialsMutation } from "@/pages/companies/[document]/actions/useCredentialsMutation.ts";
import { PendingButton } from "@/components/pending-button";

const loginSchema = z.object({
  username: z.string().min(1, "Por favor, adicione um usuário"),
  password: z.string().min(1, "Por favor, adicione uma senha"),
});

type Login = z.infer<typeof loginSchema>;

export function CredentialsAdd() {
  const { document } = useParams();

  const form = useForm<Login>({
    resolver: zodResolver(loginSchema),
    defaultValues: {
      username: "",
      password: "",
    },
  });

  const { mutateAsync: createCredentials, isPending } =
    useUpdateCredentialsMutation({
      document,
      type: "credentials",
    });

  const submit = async () => {
    const validation = await createCredentials({
      username: form.getValues("username"),
      password: form.getValues("password"),
    });

    if (validation.status !== 200) {
      form.setError("root", {
        type: "manual",
        message: "Ocorreu um erro ao salvar suas credenciais.",
      });
      return;
    }
  };

  return (
    <div>
      <p className="font-semibold text-lg">Cadastrar usuário da prefeitura</p>
      <p className="font-sm text-muted-foreground mt-2">
        Em certas prefeituras, não é possível utilizar um certificado digital
        para se autenticar. Nesses casos, é necessário utilizar um usuário e
        senha cadastrados no sistema da prefeitura.
      </p>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(submit)} className="space-y-8">
          <div className="mt-3">
            <FormField
              name="username"
              render={({ field }) => (
                <FormItem className="mt-6">
                  <FormLabel>Usuário</FormLabel>
                  <FormControl>
                    <Input {...field} type="text" />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              name="password"
              render={({ field }) => (
                <FormItem className="mt-6">
                  <FormLabel>Senha</FormLabel>
                  <FormControl>
                    <Input {...field} type="password" />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <PendingButton pending={isPending} type="submit">
            {isPending ? "Salvando..." : "Salvar credenciais"}
          </PendingButton>
        </form>
      </Form>
    </div>
  );
}
