import { DataTable, useGenericTable } from "@/components/table/data-table.tsx";
import { columns } from "@/pages/users/columns.tsx";
import { ViewUserDetails } from "@/pages/users/components/view-user-details.tsx";
import { useNavigate, useSearchParams } from "react-router-dom";
import { DataTablePagination } from "@/components/table/data-table-pagination.tsx";
import { useQuery } from "@tanstack/react-query";
import {
  PageContainer,
  PageContent,
  PageDescription,
  PageHeader,
  PageTitle,
} from "@/components/layout/page.tsx";

export function Users() {
  const { data } = useQuery<{
    users: {
      id?: string;
      name: string;
      email: string;
      role: "admin" | "operator" | "user";
      status: "active" | "inactive" | "deleted";
    }[];
  }>({
    queryKey: ["users"],
  });

  const navigate = useNavigate();

  const table = useGenericTable({
    columns,
    data: data?.users ?? [],
  });

  const [search] = useSearchParams();
  const userId = search.get("id");

  return (
    <PageContainer>
      <PageHeader>
        <PageTitle>Usuários</PageTitle>
        <PageDescription>
          Gerencie todos os usuários que estão cadastrados no sistema.
        </PageDescription>
      </PageHeader>
      <PageContent>
        <div className="space-y-4">
          <DataTable table={table} />
          <DataTablePagination table={table} />
        </div>
        <ViewUserDetails
          key={userId}
          userId={userId}
          onOpenChange={(open) => {
            if (!open) {
              navigate(-1);
            }
          }}
        />
      </PageContent>
    </PageContainer>
  );
}
